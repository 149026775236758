<template>
  <div v-if="isAdmin || isUserEditMode" class="panel-admin-body">
    <div class="d-flex title">
        {{ title }}
        <div v-show="isUserEditMode" class="ml-auto">
          <span class="wizard-icon">
            <!--
            <font-awesome-icon  v-tooltip="tooltipConfigShow" :icon="['fas', 'hat-wizard']" @click="$root.toggleWizardContent()"/>
            -->
            <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
          </span>
        </div>        
    </div>
    <div v-show="isUserEditMode && $root.getShowWizardContent()" class="wizard-content">
      <div class="wizard-content-column-two-entries wizard-border col-6">
        <div class="w-100 d-flex">
          <div class="circle">1</div>
          <div class="wizard-text">
            Enter your First Name and Last Name to adjust how it appears in the top right of the wRatings System.

          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">2</div>
          <div class="wizard-text">
            Enter your Email to change your system login. Your Email controls where the System sends Advisor messages, and sends updates about the system and new versions of The W Reports™.
          </div>
        </div>
      </div>
      <div class="wizard-content-column-two-entries col-6">
        <div class="w-100 d-flex">
          <div class="circle">3</div>
          <div class="wizard-text">
            To change your password, enter your current one in Old Password and new one in New Password. Your password must 8 or more characters and include a number, capital letter and a special character.
          </div>
        </div>
        <div class="w-100 d-flex pt-3">
          <div class="circle">4</div>
          <div class="wizard-text">
            Click Import Logo to change the logo at the top right of the wRatings system. Your Logo appears at the Organization level. Click Save Logo and then Save User. Logo changes appear the next time you login.
          </div>
        </div>
        <div class="w-100 d-flex pt-3">
          <div class="circle">5</div>
          <div class="wizard-text pt-2">
            Click Save User to ensure all changes made for your next system login.
          </div>
        </div>
      </div>
    </div>
    <form accept-charset="UTF-8" enctype="multipart/form-data" autocomplete="off" class="form" action="#" method="post" novalidate="novalidate" id="UserFormId">
      <div v-if="getDisplayView()" class="add-user mx-auto">
        <div v-show="!isUserEditMode" class="pt-2 pb-2">
          <router-link :to="{ name: 'user-list' }">
            <button type="button" class="btn btn-success" id="ReturnToUserListButton">Return to User List</button>
          </router-link>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-row">
              <div v-if="isAdminEditMode" class="col-lg-3">
                <div class="form-group">
                  <label for="userId">User Id</label>
                  <div v-if="mode == 'edit-user'">{{ formUserObject.id }}</div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label class="w-100" for="active">Status</label>
                  <select id="active" v-show="!isUserEditMode" name="active" class="custom-select col-9 required" v-model="formUserObject.active">
                    <option value>Select</option>
                    <option v-for="(option, idxActiveOptions) in this.getActiveOptions()" v-bind:value="option.value" v-bind:key="idxActiveOptions">{{ option.name }}</option>
                  </select>
                  <div v-show="isUserEditMode">{{ statusName }}</div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="userType" class="w-100">User Type</label>
                  <select v-show="!isUserEditMode" id="userType" name="userType" class="custom-select required col-9" v-model="formUserObject.userTypeId" @change="setSubscriptionEndDate()">
                    <option value>Select</option>
                    <option v-for="(option, idxUserTypeOptions) in this.user_type_options" v-bind:value="option.userTypeId" v-bind:key="idxUserTypeOptions">
                      {{ option.userTypeName }}
                    </option>
                  </select>
                  <div v-show="isUserEditMode">{{ formUserObject.userTypeName }}</div>
                </div>
              </div>
              <div v-show="!displayMultipleOrganizationDropdown" class="col-lg-3">
                <div class="form-group">
                  <label for="clientId">Organization</label>
<!-- VUE 2 implementation
                  <treeselect
                    v-show="!isUserEditMode"
                    v-model="formUserObject.clientCode"
                    :multiple="false"
                    :show-count="true"
                    :options="clientOptions"
                    :append-to-body="true"
                    :required="true"
                    :maxHeight="400"
                    :disabled="!isAdminUserType && !isAdminAddMode && !isAdminEditMode && !isAdminAddMode"
                    placeholder="Search..."
                  />
-->            
                  <Treeselect
                    v-show="!isUserEditMode"
                    :model-value="formUserObject.clientCode"
                    :is-single-select="true"
                     class="no-shadow"
                    :show-count="true"
                    :options="clientOptions"
                    :append-to-body="true"
                    :required="true"
                    placeholder="Search..." 
                    />                        
                  <div v-show="isUserEditMode">{{ clientName }}</div>
                </div>
              </div>
              <div v-show="displayMultipleOrganizationDropdown" class="col-lg-3">
                <div class="form-group">
                  <label for="clientId">Client</label>
<!-- VUE 2 implementation                  
                  <treeselect
                    v-show="!isUserEditMode"
                    v-model="userClients"
                    :multiple="true"
                    :show-count="true"
                    :options="clientOptions"
                    :append-to-body="true"
                    :required="true"
                    :maxHeight="400"
                    :disabled="!isAdminUserType && !isAdminAddMode && !isAdminEditMode && !isAdminAddMode"
                    placeholder="Search..."
                  />
                -->
                  <Treeselect
                    v-show="!isUserEditMode"
                    :model-value="userClients"
                     @update:modelValue="onInput"
                    :is-single-select="false"
                    :show-count="true"
                    is-independent-nodes="true"
                    :options="clientOptions"
                    :append-to-body="true"
                    :required="true"
                    :maxHeight="400"
                    :disabled="!isAdminUserType && !isAdminAddMode && !isAdminEditMode && !isAdminAddMode"
                    :show-tags="true"
                    placeholder="Search..."
                  />

                  <div v-show="isUserEditMode">{{ clientName }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-row">
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="firstName" class="text-right">First Name</label>
                  <input v-model="formUserObject.firstName" id="firstName" name="firstName" type="text" class="form-control col-9 required" />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="lastName" class="text-right">Last Name</label>
                  <input v-model="formUserObject.lastName" id="lastName" name="lastName" type="text" class="form-control col-9 required" />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="comp2">Email</label>
                  <input v-model="formUserObject.email" id="email" name="email" class="form-control col-9 required" type="text" emailCheck="emailCheck"/>
                </div>
              </div>
              <div v-show="!isUserEditMode && showSubscriptionEndDate" class="col-lg-3">      
                  <label for="comp5" class="text-right">Subscription End Date</label>
                  <datepicker
                    format="MMM dd yyyy"
                    v-model="formUserObject.subscriptionEndDate"
                    wrapper-class=""
                    input-class="form-control required w-100 bg-white"
                    name="subscriptionEndDate"
                  ></datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-row">
              <div class="d-none col-lg-3">
                <label for="userName">User Name</label>
                <input v-model="formUserObject.userName" id="userName" name="userName" class="form-control col-9 required" type="text" />
              </div>
              <div v-if="isUserEditMode" class="col-lg-3">
                <label for="oldPassword">Old Password</label>
                <input v-model="oldPassword" id="oldPassword" name="oldPassword" :class="[requirePassword ? requiredPasswordClass : basePasswordClass]" type="password" />
              </div>
              <div class="col-lg-3">
                <label for="newPassword">Your New Password</label>
                <input
                  v-model="newPassword"
                  id="newPassword"
                  name="newPassword"
                  :class="[requirePassword ? requiredPasswordClass : basePasswordClass]"
                  type="password"
                  passwordCheck="passwordCheck"
                  autocomplete="new-password"
                />
              </div>
              <div class="col-lg-3">
                <label for="confirmPassword">Retype Your New Password</label>
                <input
                  v-model="confirmPassword"
                  id="confirmPassword"
                  name="confirmPassword"
                  :class="[requirePassword ? requiredPasswordClass : basePasswordClass]"
                  type="password"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <company-logo v-if="!isAdminAddMode && !isAdminEditMode" :initial-mode="mode" :initial-logo-id="formUserObject.logoId"></company-logo>
        </div>
        <div class="row pt-3">
          <div class="col-lg-12" role="group">
            <button type="submit" class="btn btn-primary float-right ml-2" @click="saveUser()" :disabled="disableButton">
              <span>{{ buttonName }}</span>
              <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
            </button>
          </div>
        </div>
        <div v-if="disableButton" class="d-flex justify-content-end text-danger">Please save the logo before saving the user</div>
        <user-settings-system :initial-mode="mode" :initial-user-id="formUserObject.id" :initial-user-code="formUserObject.userCode" 
          :initial-user-type-id="formUserObject.userTypeId"
          v-if="isAdminAddMode || isAdminEditMode" />

      </div>
    </form>
  </div>
  <div v-else class="panel-admin-body user-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";
import Datepicker from "vuejs-datepicker";

require("jquery-validation");
import CompanyLogo from "../company/CompanyLogo.vue";
import AdminMixins from "../../mixins/AdminMixins.vue";
import UserSettingsSystem from "../../components/UserSettingsSystem.vue";
import Treeselect, { DirectionType, IconsType, OptionType, TreeselectValue } from 'vue-treeselectjs'
import 'vue-treeselectjs/dist/vue-treeselectjs.css'
import { reactive, toRaw } from 'vue'

import UserService from "../../services/user.service";
import ClientService from "../../services/client.service";
import AdminService from "../../services/admin.service";
import ValidatorService from "../../services/validator.service";

export default {
  name: "AddUser",
  mixins: [AdminMixins],
  components: {CompanyLogo, Datepicker,UserSettingsSystem,Treeselect},
  data() {
    return {
      mode: "",
      userClients: [],
      userForm: null,
      validator: null,
      basePasswordClass: "form-control col-9",
      requiredPasswordClass: "form-control col-9 required passwordRequired",
      title: "Add New User",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      disableButton: false,
      formUserObject: {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        logoId: 0
      },
      user_type_options: [],
      clientOptions: [],
      summernoteConfiguration: {
        direction: "ltr",
        height: 100,
        tabsize: 2,
        dialogsInBody: true,
        toolbar: [
          ["style", ["fontname", "fontsize", "color", "bold", "italic", "underline", "strikethrough", "clear"]],
          ["Paragraph style", ["style", "ol", "ul", "paragraph", "height"]],
          ["Misc", ["fullscreen", "codeview", "undo", "redo", "help"]],
          ["mybutton", ["hello"]]
        ]
      }
    };
  },
  methods: {
    onInput(value) {
      this.userClients = value;
    },
    getUserTypeName(){
      let selectedUserType = this.user_type_options.find(userType => userType.userTypeId == this.formUserObject.userTypeId);
      if (typeof selectedUserType != "undefined"){
        return selectedUserType.userTypeName;
      }
      return "";
    },
    setSubscriptionEndDate(){
      let future = new Date();
      if (this.getUserTypeName() == "Insider User"){
        future.setDate(future.getDate() + 30);
      } else {
        future.setFullYear(2030, 0, 1);
      }
      this.formUserObject.subscriptionEndDate = future;
    },
    onSelect(option) {
      let index = this.clientOptions.findIndex(item => item.label == option.label);
      this.clientOptions[index].checked = true;
    },
    onRemove(option) {
      let index = this.clientOptions.findIndex(item => item.label == option.label);
      this.clientOptions[index].checked = false;
    },
    getActiveOptions() {
      return AdminService.getActiveOptions();
    },
    async getUser() {
      this.$root.processServerRequest("Retrieving user");
      let userCode = (this.initialUserCode == "0" && this.isAdminAddMode) || this.isUserEditMode ? UserService.getUserCode() : this.initialUserCode;
      UserService.getUserFromServer(userCode, this.getUserCallback, this);
    },
    getUserCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.formUserObject = response.data.data;
        this.oldPassword = this.formUserObject.password;
        if (this.isAdminDuplicateMode) {
          this.formUserObject.id = 0;
          this.formUserObject.userName = this.formUserObject.userName + "1";
        }
        this.setUserClients();
        let doesClientExistInList = this.userClients.find(clientCode => clientCode == this.formUserObject.clientCode);
        if (typeof doesClientExistInList == "undefined") {
            if (this.doesClientExistInClientOptions == true) {
              this.userClients.push(this.formUserObject.clientCode);
            } else {
              this.formUserObject.clientCode = undefined;
            }
        }
        this.setDisplayView(true);
        let vm = this;
        $(document).ready(function() {
          $("#address").summernote(vm.summernoteConfiguration);
          $("#address").summernote("code", vm.formUserObject.address);
        });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    setUserClients() {
      const vm = this;
      $.each(this.formUserObject.userClients, function(i, item) {
        let inputCompetitor = item;
        let foundClient = vm.clientOptions.findIndex(client => client.id == inputCompetitor.clientCode);
        if (typeof foundClient != "undefined") {
          vm.userClients[i] = inputCompetitor.clientCode;
        }
      });
    },
    async saveUser() {
      if (!this.userForm.valid()) {
        return false;
      } else {
        this.formUserObject.userName = this.formUserObject.email;
        this.$root.processServerRequest("Saving user");
        if (this.newPassword != "") {
          this.formUserObject.password = this.newPassword;
        } else {
          delete this.formUserObject["password"];
        }
        UserService.saveUser(this.mode, this.formUserObject, this.userClients, this.saveUserCallback, this);
      }
    },
    saveUserCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.formUserObject = response.data.data;
        this.oldPassword = this.formUserObject.password;
        if (this.mode == "add-user" || this.mode == "duplicate-user") {
          this.mode = "edit-user";
        } else if (this.mode == "user-settings") {
          UserService.saveUserToLocalStorage(this.formUserObject);
        }
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    loadUserTypesCallback(response) {
      this.user_type_options = response.data;
    },
    async loadUserTypes() {
      UserService.loadUserTypes(this.loadUserTypesCallback, this);
    },
    async loadData() {
      this.loadUserTypes();
      ClientService.getAllClientsFromDatabase(this.loadClientDataCallback);
    },
    loadClientDataCallback(data) {
      this.clientOptions = data;
      this.clientOptions = this.clientOptions.filter(function(inputClient) {
        let client = toRaw(inputClient);
        return typeof client.id != "undefined";
      });
      let vm = this
      this.clientOptions = this.clientOptions.map(function(inputClient) {
        let client = toRaw(inputClient);
        return vm.resetClientForOptions(client);        
      });
    },
    // Vue 3 migration - after the migration we will need to recode the server side to return the correct data.
    // once we do that we will be able to remove these functions
    resetClientForOptions: function(client) {
      let resetClientForOptionsRecursive = function(client) {
        let updatedClient = {};
        updatedClient.value = client.id;
        if (typeof client.children === "undefined") {
          updatedClient.children = [];
        } else {
          updatedClient.children = client.children.map(resetClientForOptionsRecursive);
        }
        if (typeof client.label !== "undefined") {
          updatedClient.name = client.label;
        }
        return updatedClient;
      };

      return resetClientForOptionsRecursive(client);
    },    
    setupUserForm() {
      this.userForm = $("#UserFormId");
      jQuery.validator.setDefaults({
        debug: true,
        success: "valid"
      });
      jQuery.validator.addMethod(
        "notEqual",
        function(value, element, param) {
          return this.optional(element) || value != param;
        },
        "Please choose a value!"
      );
      jQuery.validator.addClassRules("passwordRequired", { required: true, minlength: 8 });
      jQuery.validator.addMethod("passwordCheck", ValidatorService.passwordCheck, ValidatorService.passwordErrorMessage);
      jQuery.validator.addMethod("emailCheck", ValidatorService.emailCheck, ValidatorService.emailErrorMessage);
      this.validator = this.userForm.validate({
        rules: {
          clientId: {
            required: true,
            notEqual: "0"
          },
          confirmPassword: {
            equalTo: "#newPassword"
          }
        },
        highlight: function(element) {
          $(element)
            .closest(".form-group")
            .removeClass("has-success")
            .addClass("has-error");
        },
        unhighlight: function(element) {
          $(element)
            .closest(".form-group")
            .removeClass("has-error")
            .addClass("has-success");
        },
        errorElement: "small",
        errorClass: "form-text text-danger",
        errorPlacement: function(error, element) {
          if (element.length) {
            var targetParent = $(element).parent();
            if (targetParent.hasClass("form-check") || targetParent.hasClass("custom-control")) {
              targetParent = targetParent.parent();
            }
            targetParent.append(error);
          }
        }
      });
    }
  },
  computed: {
    doesClientExistInClientOptions: function(){
      let result = false;
      let doesClientExistInClientOptions = this.clientOptions.findIndex(client => client.id == this.formUserObject.clientCode);
      result = typeof doesClientExistInClientOptions != "undefined";
      return result;
    },
    showSubscriptionEndDate: function(){
      return this.getUserTypeName() == "Insider User";
    },
    buttonName: function() {
      if (this.mode == "edit-user" || this.mode == "user-settings" || this.mode == "duplicate-user") {
        return "Save User";
      } else {
        return "Add User";
      }
    },
    statusName: function() {
      if (this.formUserObject) {
        return this.formUserObject.active == "Y" ? "Active" : "Inactive";
      } else {
        return "";
      }
    },
    displayMultipleOrganizationDropdown: function() {
      return this.formUserObject.userTypeId == 4 || this.formUserObject.userTypeId == 5 || this.formUserObject.userTypeId == 2 || this.formUserObject.userTypeId == 6;
    },
    isAdminAddMode: function() {
      return this.mode == "add-user";
    },
    isAdminEditMode: function() {
      return this.mode == "edit-user";
    },
    isAdminDuplicateMode: function() {
      return this.mode == "duplicate-user";
    },
    isUserEditMode: function() {
      return this.mode == "user-settings" || this.mode == "";
    },
    isAdminUserType: function() {
      return this.formUserObject.userTypeId == "1";
    },
    requirePassword: function() {
      return this.newPassword != "" || this.isAdminDuplicateMode || this.isAdminAddMode;
    }
  },
  watch: {
    displayView: function() {
      this.setupUserForm();
    }
  },
  async created() {
    this.mode = this.$route.name;
    if (this.mode == "edit-user" || this.mode == "add-user" || this.initialMode == "duplicate-user") {
      if (this.initialMode == "duplicate-user") {
        this.mode == this.initialMode;
      }
      await this.loadData();
      if (this.mode == "edit-user") {
        this.title = "Modify User";
        this.getUser();
      } else if (this.mode == "duplicate-user") {
        this.title = "Copy User";
        this.getUser();
      } else {
        this.title = "Add User";
        this.setDisplayView(true);
        let vm = this;
        $(document).ready(function() {
          $("#address").summernote(vm.summernoteConfiguration);
        });
      }
    } else {
      this.title = "User";
      this.mode = "user-settings";
      this.getUser();
    }
  },
  props: {
    initialMode: {
      type: String,
      default: ""
    },
    initialUserId: {
      type: Number,
      default: 0
    },
    initialUserCode: {
      type: String,
      default: "0"
    }
  },
  mounted() {}
};
</script>
