<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" @opened="loadData" :adaptive="true" :resizable="true" :scrollable="true" width="820" :maxHeight="1050" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div v-show="getDisplayView()" class="modal-body manage-benchmark-exclusion-list">
        <div class="text-center m-1 info-message">{{ returnMessage }}</div>
        <div class="pb-2">Select the companies that will not appear for this client. After saving, click the Apply to Client Hierarchy button to copy benchmark companies to all descendents.</div>
        <div class="dashboard-divider w-100"></div>
        <div class="form-row w-100">
            <div class="form-group col-lg-2 col-md-2 col-sm-2" v-for="(chart_company_option, idxChartCompanyOptions) in benchmarkCompanyList" v-bind:key="idxChartCompanyOptions">
                <input
                    class="competitor_option mr-1"
                    type="checkbox"
                    :checked="isExcluded(chart_company_option.companyCode)"
                    @id="chart_company_option.companyCode"
                    @value="chart_company_option.companyCode"
                    @name="chart_company_option.companyCode"
                    @click="updateInput(chart_company_option, $event)"
                />
                <label class="form-check-label" v-bind:for="chart_company_option.companyCode">{{ chart_company_option.name }}</label>
            </div>
        </div>
        <div class="dashboard-divider w-100 d-none" style="margin-top: 10px;"></div>
      </div>
      <div class="modal-footer justify-content-between">
        <div>
            <button type="button" class="btn btn-primary ml-4" @click="unSelectAll">Unselect All</button>
            <button type="button" class="btn btn-primary ml-4" @click="selectAll">Select All</button>
        </div>
        <div>
            <button type="button" class="btn btn-primary ml-4" @click="saveList" :disabled="disableSave">Save</button>
            <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import $ from "jquery";

import AdminService from "../services/admin.service";
import BenchmarkCompanyService  from "../services/benchmark.company.service";
import ViewMixins from "../mixins/ViewMixins.vue";

export default {
  name: "ManageBenchmarkExclusionList",
  mixins: [ViewMixins],
  components: {},
  data() {
    return {
      benchmarkCompanyExclusionList: [],
      benchmarkCompanyList: [],      
      returnMessage: "",
      disableSave: false,
      refreshModal: true  
    };
  },
  props: {
    inputClientCode: {
        type: String,
        default: ""
    },     
    modalId: {
      type: String,
      default: "manage-benchmark-exclusion-list",
    },
    modalTitle: {
      type: String,
      default: "Select Benchmark Companies to Exclude",
    },
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    selectAll: function(){
        this.benchmarkCompanyExclusionList = [];
        for (let i = 0; i < this.benchmarkCompanyList.length; i++) {
            this.benchmarkCompanyExclusionList.push(this.benchmarkCompanyList[i].companyCode);
        }
    },
    unSelectAll: function(){
        this.benchmarkCompanyExclusionList = [];
    },
    isExcluded(companyCode) {
        return this.benchmarkCompanyExclusionList.includes(companyCode);
    },
    addToList(benchmark) {
        if (typeof benchmark === 'undefined') {
            return;
        }
        this.benchmarkCompanyExclusionList.push(benchmark.companyCode);
    },
    deleteFromList(benchmark){
        if (typeof benchmark === 'undefined') {
            return;
        }
        this.benchmarkCompanyExclusionList.splice($.inArray(benchmark.companyCode, this.benchmarkCompanyExclusionList), 1);
    },
    updateInput(benchmark, event) {
      if (event.target.checked) {
        this.addToList(benchmark);
      } else {
        this.deleteFromList(benchmark);
      }    
    },    
    closeModal() {
      this.setDisplayView(false);
      this.$refs[this.modalId].visible = false;
      this.refreshModal = !this.refreshModal;
    },
    saveList(){
        this.disableSave = true;
        BenchmarkCompanyService.saveBenchmarkExclusionList(this.inputClientCode, this.benchmarkCompanyExclusionList, this.getBenchmarkExclusionListCallback, this);
    },
    getBenchmarkExclusionListCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.benchmarkCompanyExclusionList = response.data.data.benchmarkCompanyExclusionList;
        this.benchmarkCompanyList = response.data.data.benchmarkCompanyList;
        this.returnMessage = response.data.message;
        this.setDisplayView(true);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
      this.disableSave = false;
    },
    loadData() {
      BenchmarkCompanyService.getBenchmarkExclusionList(this.inputClientCode,this.getBenchmarkExclusionListCallback, this);
    },
  },
  watch: {},
};
</script>
