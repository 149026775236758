<template>
    <div v-if="isAdmin" class="panel-admin-body">
        <div class="title">{{ title }}</div>
        <div class="container survey-list">
            <div class="filter-container border-bottom pt-3 pb-3">      
                <div class="row">
                    <div class="form-group form-inline col-4">
                        <label for="client" class="mr-1">Client</label>
                        <select id="client" name="client" class="custom-select required" v-model="client_id" @change="filterSurveyOptions">
                        <option
                            v-for="(client_option, idxClientOptions) in this.client_options"
                            v-bind:value="client_option.value"
                            v-bind:key="idxClientOptions"
                        >
                            {{ client_option.name }}
                        </option>
                        </select>
                    </div>
                    <div class="form-group form-inline ml-2 col-5">
                        <label for="survey" class="mr-1">Survey</label>
                        <select id="survey" name="survey" class="custom-select required" v-model="survey_id">
                        <option
                            v-for="(survey_option, idxSurveyOptions) in this.survey_options"
                            v-bind:value="survey_option.value"
                            v-bind:key="idxSurveyOptions"
                        >
                            {{ survey_option.name }}
                        </option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="pr-2">
                        <button type="button" class="btn btn-primary ml-2" id="FilterButton" @click="filterData()">Filter</button>
                    </div>
                    <div class="pr-2">
                        <button type="button" class="btn btn-primary ml-2" id="ResetButton" @click="reset()">Reset</button>
                    </div>
                </div>

            </div>
            <div class="button-container pt-1 pb-1">
                <div class="button-left">
                    <div class="pr-2">
                        <button type="button" class="btn btn-primary" id="DuplicateSurveyButton" :disabled="!copyCardButtonEnabled" @click="copySavedCard">
                        Copy Card
                        <span class="ml-auto">
                            <font-awesome-icon :icon="['fa', 'copy']" />
                        </span>
                        </button>
                    </div>
                    <div class="pr-2">
                        <a href="#" @click="displayDeleteConfirmation(deleteClientCharts,table.rowSelectedId,'Delete Confirmation Card','Are you sure you want delete the selected cards ?')">
                        <button type="button" class="btn btn-primary delete-card" id="DeleteSurveyButton" :disabled="!deleteCardButtonEnabled">
                            Delete Card 
                            <span class="ml-auto">
                            <font-awesome-icon :icon="['fa', 'minus-circle']" />
                            </span>
                        </button>
                        </a>
                    </div>
                </div>
            </div>
            <div>
                Reminder: Only one card can be copied at a time.
            </div>
            <div class="data-container pt-1 pb-1">
                <div class="col-12">
                    <table :id="table.id" :class="table.class" cellspacing="0" width="100%" style="width: 100%;">
                        <thead></thead>
                        <tbody></tbody>
                    </table>
                </div>
            </div>
        </div>
        <copy-saved-card ref="copySavedCard" 
            :input-saved-card="savedCard"
            :input-survey-group-options="survey_group_options"
            @save="reload"/>
    </div>
</template>

<script>
import $ from "jquery";

import FilterMixins from "../../mixins/FilterMixins.vue";
import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import ClientChartService from "../../services/client.chart.service";
import AdminService from "../../services/admin.service";
import ReportService from  "../../services/report.service";
import ChartService from   "../../services/chart.service";
import CopySavedCard from "../../modals/CopySavedCard.vue";

export default {
    name: "SavedCardList",
    mixins: [AdminMixins, DataTableMixins,FilterMixins],
    components: {CopySavedCard},
    data() {
        return {
            title: "Saved Card List",
            table: {
                id: "saved-card-list-table",
                class: "table display wrap row-border table-hover",
                configuration: {
                dom: '<"top"if>rtp<"bottom"l><"clear">',
                searching: true,
                paging: true,
                select: true,
                destroy: true,
                info: false,
                ordering: true,
                bSort: true,
                bSortable: true,
                keys: true,
                autoWidth: false,
                processing: true,
                responsive: false,
                },
                columns: ClientChartService.getAdminSavedCardTableColumnData()
            },
            tableSortBy: [8, "desc"],
            returnMessage: "",
            clientChartIdsToDelete: [],
            numberOfCopies: 1,
            client_id: 0,
            client_options: [],
            survey_id: 0,
            survey_options: [],
            survey_group_options: [],
        }
    },    
    props: {},
    methods: {
        copySavedCard(){
            this.$modal.show('copy-saved-card');
        },
        async deleteClientCharts(result) {
            ClientChartService.deleteClientCharts(this.clientChartIdsToDelete,this.deleteClientChartsCallback,this);
        },     
        deleteClientChartsCallback(response) {
            this.$root.processServerResponse();
            let statusCode = response.data.statusCode;
            if (statusCode == 200) {
                AdminService.displaySuccessMessage({ text: response.data.message });
                this.clientChartIdsToDelete = [];
                this.getTableData();
            } else {
                AdminService.displayErrorMessage({ text: response.data.message });
            }
        },
        async getTableData() {
            this.originalList = [];
            this.$root.processServerRequest("Loading table data");
            ClientChartService.getSavedCardList(this.getSavedCardTableDataCallback, this);
        },
        async getSavedCardTableDataCallback(response)  {
            this.originalList = response.data.data.savedCardList;
            this.survey_group_options = response.data.data.surveyGroupList;
            this.survey_group_options.sort(ReportService.compareValues("name", "asc"));
            this.client_options = ClientChartService.buildClientOptionList(this.originalList);
            this.survey_options = ClientChartService.buildSurveyOptionList(this.originalList);
            await this.filterData();            
            this.table.configuration.order = this.tableSortBy;
            this.setupRowClick();
            this.$root.processServerResponse();
        },
        filterData(){
            let goAheadAndFilterData = false;
            if (this.survey_id > 0){
                goAheadAndFilterData = true;
                this.filteredList =  ClientChartService.getClientChartsBySurvey(this.originalList, this.survey_id, true);
            }
            if (this.client_id > 0){
                goAheadAndFilterData = true;
                this.filteredList =  ClientChartService.getClientChartsByClient(this.originalList, this.client_id, true);
            }
            if (goAheadAndFilterData == true){
                this.client_options = ClientChartService.buildClientOptionList(this.filteredList);
                this.survey_options = ClientChartService.buildSurveyOptionList(this.filteredList);
                this.table.data = this.filteredList;
            } else {
                this.table.data = this.originalList;
            }
            this.setupTable();
        },
        setupRowClick(){
            const vm = this;
            if( this.table.dataTable.click == undefined)  {

                this.table.dataTable.on("click", 'input[type="checkbox"]', function(e, dt, type, indexes) {
                    var $row = $(this).closest("tr");
                    var data = vm.table.dataTable.row($row).data();
                    if (!$.isEmptyObject(data)) {
                        if (e.target.checked) {
                            if (!vm.clientChartIdsToDelete.includes(data.clientChartId)){
                                vm.clientChartIdsToDelete.push(data.clientChartId);
                            }
                        } else {
                            var match = vm.clientChartIdsToDelete.find(x => x === data.clientChartId);
                            if (typeof match !== "undefined") {
                                vm.clientChartIdsToDelete.splice($.inArray(match, vm.clientChartIdsToDelete), 1);
                            }
                        }
                    }
                });       
        }

        },
        async reload(message){
            await this.getTableData();
            this.$refs['copy-saved-card'].visible = false;
            AdminService.displaySuccessMessage({ text: message });
        },
        reset(){
            this.table.data = this.originalList;
            this.client_options = ClientChartService.buildClientOptionList(this.originalList);
            this.survey_options = ClientChartService.buildSurveyOptionList(this.originalList);
            this.client_id = 0;
            this.survey_id = 0;           
            this.table.configuration.order = this.tableSortBy;
            this.setupTable();
        },
        filterSurveyOptions(){
            this.survey_options = ClientChartService.buildSurveyOptionList(this.originalList, this.client_id);
        },
        isSavedDasboardCard(clientChart){
            return clientChart.dashboardCardId != 0;
        }
    },   
    computed: {
        deleteCardButtonEnabled: function(){
            return this.clientChartIdsToDelete.length > 0;
        },
        copyCardButtonEnabled: function(){
          let isCopyCardEnabled = this.table.rowSelected;
          if(isCopyCardEnabled == true){
            if (!$.isEmptyObject(this.getDataFromSelectedRow())) {            
                return this.getDataFromSelectedRow().displayType != "winners" && !this.isSavedDasboardCard(this.getDataFromSelectedRow());
            } else {
                isCopyCardEnabled == false;
            }
          }
          return isCopyCardEnabled;
        },
        savedCard: function(){
            if (!$.isEmptyObject(this.getDataFromSelectedRow())) {            
                return this.getDataFromSelectedRow();
            }
            return {};
        }
    },
    created() {
        this.getTableData();
    },    
    mounted() {}, 
    watch : {}   
};
</script>