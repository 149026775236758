<template>
  <vue-modal :key="refreshModal" :ref="modalName" :name="modalName" @opened="loadData" :scrollable="true" :clickToClose="false" :draggable="true" :width="568" :maxHeight="800" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Copy Saved Card</h5>
        <button type="button" class="close" @click="closeWindow">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="copy-saved-card modal-body">
        <div class="container">
            <div class="copy-saved-card-row">
                <label for="name" >Card Template to Use</label>
                <span>{{ inputSavedCard.name }}</span>
            </div>
            <div class="copy-saved-card-row">
                <label for="name" >New Card Name</label>
                <input v-model="newCardName" id="name" name="name" type="text" class="form-control col-8 w-75" />
            </div>
            <div class="copy-saved-card-row">
                <label for="name" >Group Name</label>
                  <select
                    id="surveyGroup"
                    name="surveyGroup"
                    class="custom-select shadow col-8 w-75"
                    v-model="surveyGroupId"
                  >
                    <option value="">Select Survey Group</option>
                    <option v-for="(option, idxSurveyGroupOptions) in inputSurveyGroupOptions" 
                            v-bind:value="option.surveyGroupId" v-bind:key="idxSurveyGroupOptions">
                      {{ option.name }}
                    </option>
                  </select>
                </div>
            <div class="divider"></div>
            <div class="copy-saved-card-row">
                <label for="name"># of Copies to Make</label>
                <span>{{ numberOfCopiesToMake }}</span>
                <div class="col text-right">
                    <button class="btn btn-primary" type="button" @click="copyClientChart">
                        <span>Make Copies</span>
                        <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
                    </button>
                </div>
            </div>
            <div class="divider"></div>
        </div>
        <div v-show="displayResults" class="container">
            <div v-if="copyCardResult.result == true" class="copy-saved-card-row ">
              <span class="text-center text-success col-12">{{ $parent.modalReturnMessage }}</span>
            </div>
            <div v-if="copyCardResult.result == false" class="copy-saved-card-row ">
              <span class="text-center text-danger col-12">{{ $parent.modalReturnMessage }}</span>
            </div>
            <div class="copy-saved-card-row">
                <label for="name"># of Copies Made</label>
                 {{ copyCardResult.numberOfCopies }}
            </div>
            <div v-show="copyCardResult.result == false" class="copy-saved-card-row">
                <table :id="table.id" :class="table.class" cellspacing="0" >
                    <thead></thead>
                    <tbody></tbody>
                </table>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-2" @click="closeWindow">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import AdminService from "../services/admin.service";
import ClientChartService from "../services/client.chart.service";
import DataTableMixins from "../mixins/DataTableMixins.vue";

export default {
  name: "CopySavedCard",
  components: {},
  mixins: [DataTableMixins],
  data() {
    return {
      showModal: false,
      displayResults: false,
      newCardName: "",
      surveyGroupId: 0,
      copyCardResult: {},
        table: {
            id: "copy-saved-error-message-list-table",
            class: "table display wrap row-border table-hover",
            configuration: {
                dom: '<"top"if>rtp<"bottom"B><"clear">',
                searching: false,
                paging: true,
                select: true,
                pageLength: 10,
                destroy: true,
                info: false,
                ordering: true,
                bSort: true,
                bSortable: true,
                keys: true,
                autoWidth: false,
                processing: true,
                responsive: true,
            },
            columns: ClientChartService.getCopySavedErrorMessageTableColumnData()
        },
        refreshModal: true 
    };
  },
  props: {
    modalName: {
      type: String,
      default: "copy-saved-card"
    },
    inputSavedCard: {
        type: Object,
        default: () => {}
    },
    inputSurveyGroupOptions: {
        type: Array,
        default: () => []
    }
  },
  computed: {
    refreshCards: function(){
      if (typeof this.copyCardResult != "undefined" && Object.prototype.hasOwnProperty.call(this.copyCardResult, "result")) {            
        return this.copyCardResult.result;
      }
      return false;      
    },
    numberOfCopiesToMake: function(){
        let returnData = this.inputSurveyGroupOptions.find(s => s.surveyGroupId == Number(this.surveyGroupId))
        if (typeof returnData != "undefined" && Object.prototype.hasOwnProperty.call(returnData, "numberOfSurveys")) {            
            return returnData.numberOfSurveys;
        } else {
            return "";
        }
    }
  },
  methods: {
    closeWindow: function(){
      this.$refs[this.modalName].visible = false;
      this.refreshModal = !this.refreshModal;
      let vm = this;
      setTimeout(() => {
            if (vm.refreshCards){
              vm.$router.go(0);
            }
        }, 100);        
    },
    copyClientChartCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$parent.modalReturnMessage = response.data.message;
        this.copyCardResult = response.data.data;
        this.table.data = this.copyCardResult.messageList;
        this.setupTable();        
        let vm = this;
        setTimeout(() => {
            vm.displayResults = true;
        }, 500);        
        
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async copyClientChart() {
        let error = false;
        if (this.newCardName == "") {
            AdminService.displayErrorMessage({ text: 'New Card Name field is required' });
            error = true;
        } 
        if (this.surveyGroupId == 0) {
            AdminService.displayErrorMessage({ text: 'Group Name field is required' });
            error = true;
        } 
        if (error == false) {
            this.$root.processServerRequest();
            ClientChartService.copyClientChart(this.inputSavedCard,this.newCardName,this.surveyGroupId,this.copyClientChartCallback,this);
        }
    },
    loadData(){
        this.$parent.modalReturnMessage = "";
        this.displayResults = false;
    }
  },
  watch:{},
  mounted() {}
};
</script>
