<template>
  <div></div>
</template>
<script>
import AdminService from "../services/admin.service";
import DashboardService from "../services/dashboard.service";
import ClientService from "../services/client.service";

export default {
  name: "DashboardCardMixins",
  mixins: [],
  components: {},
  data() {
    return {
      clientId: ClientService.getClientId(),
      clientChart: {
        clientChartId: 0
      },     
      dashboardLayoutCards: [],
      metric_options: [],
      modalWidth: "30%",
      defaultGroup: { dashboardCardId: 0, id: 0, sequenceNumber: 100, dashboardCardTypeCode: "" },
      refreshModal: true
    };
  },
  props: {
    modalId: {
      type: String,
      default: "saved-dashboard-card"
    },
    modalTitle: {
      type: String,
      default: "Saved"
    },
    clientObject: {
      type: Object,
      default: function() {
        return {};
      }
    }, 
    saveClientChartObject: {
      type: Object,
      default: function() {
        return {
          clientChartId: 0
        };
      }
    }
  },
  created() {},
  methods: {
    getDashboardLayoutCard: function(layoutCards, code) {
      return DashboardService.getDashboardLayoutCard(layoutCards, code);
    },
    getAllDashboardCardTypesAsCardsCallback: function (response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.dashboardLayoutCards = response.data.data;
        let currentSequenceNumber = this.dashboardLayoutCards.length;
        let cardToAdd = JSON.parse(JSON.stringify(this.defaultGroup));
        currentSequenceNumber += 1;
        cardToAdd.sequenceNumber = currentSequenceNumber;
        this.dashboardLayoutCards.push(cardToAdd);
        DashboardService.saveAllDashboardCardTypesAsCards(this.dashboardLayoutCards);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },    
    setupDashboardCard: async function(){
      let dashboardCardTypes = await DashboardService.getAllDashboardCardTypesAsCards();
      if (dashboardCardTypes.length == 0){
        await DashboardService.getAllDashboardCardTypesAsCardsFromDatabase(this.getAllDashboardCardTypesAsCardsCallback, this);
      } else {
        this.dashboardLayoutCards = dashboardCardTypes;
      }
      if (this.$refs.performanceTrends) {
        this.$refs.performanceTrends.initialLoadData();
      }
      if (this.$refs.competitiveTrends) {
        this.$refs.competitiveTrends.initialLoadData();
      }      
      if (this.$refs.metricContributions) {
        this.$refs.metricContributions.initialLoadData();
      }      
      if (this.$refs.predictiveAnalytics) {
        this.$refs.predictiveAnalytics.initialLoadData();
      }     
      if (this.$refs.npsRank){
        this.$refs.npsRank.initialLoadData();
      }
      if (this.$refs.moatScoreRank){
        this.$refs.moatScoreRank.initialLoadData();
      }
      if (this.$refs.wScoreRank){
        this.$refs.wScoreRank.initialLoadData();
      }       
    },
    loadData: function(){},
    closeModal() {
      this.$refs[this.modalId].visible = false;
      this.refreshModal = !this.refreshModal;
    },
    getCriteriaParameterForDashboardCard(saveClientChartObject, parameterName){
      let criteriaArray = saveClientChartObject.criteria.split("&");
      for (let criteriaIndex = 0; criteriaIndex < criteriaArray.length; criteriaIndex++) {
        let criteria = criteriaArray[criteriaIndex];
        let criteriaParameters = criteria.split("=");
        let defaultParameter = criteriaParameters[0];
        let defaultValue = criteriaParameters[1];
        let isMetricId = defaultParameter == parameterName ? true : false;
        if (isMetricId){
          return defaultValue;
        }
      }
      return "";
    },     
  },
  watch: {
    saveClientChartObject: function() {
      this.loadData();
    }
  },
  mounted() {},
  computed: {}
};
</script>
