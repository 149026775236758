<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" :adaptive="true" @opened="setHeight" :resizable="true" :width="modalWidth" :height="'auto'">
    <div id="preview-locale-modal" class="modal-content" style="overflow: auto;">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
            <div :class="isError ? 'form-group form-row text-danger' : 'form-group form-row text-success'">
                {{ modalReturnMessage }}
            </div>
            <div style="min-height: 100px; overflow-y: auto;">
              <div class="text-center">Welcome to the Survey Preview. This is how the survey will display to the end user for the langauge you selected. 
                        The widgets are functional but the buttons are disabled.</div>
              <survey-taker
                :read-only="true"
                :view-only="true"
                :entry-language-code="inputSurveyLocale.languageCode"
                :entry-client-id="getClientId"
                :entry-survey-url="inputSurveyLocale.surveyUrl"
                :entry-survey-code="inputSurveyLocale.surveyCode"
              ></survey-taker>
            </div>                
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";

import AdminService from "../services/admin.service";
import LocaleService from "../services/locale.service";
import ViewMixins from "../mixins/ViewMixins.vue";
import DataTableMixins from "../mixins/DataTableMixins.vue";
import SurveyTaker from "../interview-application/views/SurveyTaker.vue";

export default {
  name: "PreviewLocale",
  mixins: [ViewMixins,DataTableMixins],
  components: {SurveyTaker},
  data() {
    return {
      isError: false,
      disableImport: false,
      modalReturnMessage: "",
      refreshModal: true
    };
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    getClientId: function(){
      if (typeof this.inputSurveyLocale.clientId !== "undefined") {
        return this.inputSurveyLocale.clientId.toString(); 
      }
      return "";
    },
    setHeight: function(){
      var $window = jQuery(window);
      let viewport_height = $window.height();
      $("#preview-locale-modal").height(viewport_height);
    },
    closeModal(){
      this.modalReturnMessage = "";
      this.$refs[this.modalId].visible = false;
      this.refreshModal = !this.refreshModal;
    },    
  },
  watch: {},
  props: {
    inputSurveyLocale: {
      type: Object,
      default: function () {
        return {};
      },
    },    
    modalId: {
      type: String,
      default: "preview-locale",
    },
    modalTitle: {
      type: String,
      default: "Preview Locale",
    },
    modalWidth: {
      type: Number,
      default: 968,
    },
  }
};
</script>
