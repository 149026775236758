<template>
  <div class="panel-admin-body advisor">
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>{{ title }}</div>
      <div class="ml-auto">
        <span class="wizard-icon">
          <!--
          <font-awesome-icon  v-tooltip="tooltipConfigShow" :icon="['fas', 'hat-wizard']" @click="$root.toggleWizardContent()"/>
          -->
          <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
        </span>
        <i
          v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()"
          class="fa fa-window-minimize pl-1 pr-1"
          aria-hidden="true"
          @click="toggleHeaderMenu()"
        ></i>
        <i
          v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()"
          class="fa fa-window-maximize pl-1 pr-1"
          aria-hidden="true"
          @click="toggleHeaderMenu()"
        ></i>
      </div>
    </div>
    <company-menu></company-menu>
    <div v-show="$root.getShowWizardContent()" class="wizard-content">
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">1</div>
          <div class="wizard-text">
            To setup the wRatings System Advisor to automatically send you updates on your respondents, click the Frequency of Notifications level to specify how often you receive an Email message.
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">2</div>
          <div class="wizard-text">
            Click each Organization you want to receive updates. To receive updates on all Organizations, leave blank (select nothing).
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">3</div>
          <div class="wizard-text">
            To receive only a summary view of all respondents, select Include Summary to receive one consolidated view for each Organization selected.
          </div>
        </div>
      </div>
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">4</div>
          <div class="wizard-text pt-2">
            To receive a view of each survey in the Organizations selected, click Include Details.
          </div>
        </div>
        <div class="w-100 d-flex pt-3">
          <div class="circle">5</div>
          <div class="wizard-text">
            Click each type of change to receive in your Email notifications for each Organization selected. Each Notification appears as a row in the update table.
          </div>
        </div>
        <div class="w-100 d-flex pt-3">
          <div class="circle">6</div>
          <div class="wizard-text">
            Enter a number to set the traffic light color next to each Notification change. Set negative thresholds for NPS, Satisfaction and Pricing Power since improvement indicates increases. Set positive thresholds for Gap Changes since improvement indicates decreases.
          </div>
        </div>
      </div>
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">7</div>
          <div class="wizard-text">
            Click the Start Wave to use when analyzing traffic light changes. Use Previous Survey Wave to previous any on-going collection efforts to this time-period. Click the Time Period to analyze how far back to previous changes for your update tables and traffic lights.
          </div>
        </div>
        <div class="w-100 d-flex pt-3">
          <div class="circle">8</div>
          <div class="wizard-text pt-2">
            Click Save Configuration.
          </div>
        </div>
      </div>
    </div>
    <div v-if="getDisplayView()" class="container">
      <div class="advisor-title">Advisor Email Notifications</div>
      <div>
        Get a summary for your competitive research and pricing power results. The W-Advisor draws upon best practices learned from wRatings
        clients. After in depth inspections, the W-Advisor automatically refreshes your results and reports back to you on the cadence you prefer.
      </div>
      <div class="advisor-edit" v-show="isAdminEdit">
        Editing the advisor for {{ initialClientName }} as an admin
      </div>
      <div class="advisor-border"></div>
      <div class="row">
        <div class="col-3">
        <div class="form-group form-inline">
            <label for="advisorNotificationFrequencyTypeId" class="col-4 align-self-start"
              ><modal-link modal-id="Frequency Of Notifications" :content-list="pageContent"></modal-link>Frequency Of Notifications</label
            >
            <div class="col align-self-start">
              <div class="w-100 form-inline" v-for="(notification_frequency_option, idxFrequencyOfNotifications) in notification_frequency_types" v-bind:key="idxFrequencyOfNotifications">
                <input
                  type="radio"
                  id="advisorNotificationFrequencyTypeId"
                  name="advisorNotificationFrequencyTypeId"
                  v-model="advisorConfiguration.advisorNotificationFrequencyTypeId"
                  :value="notification_frequency_option.advisorNotificationFrequencyTypeId"
                />
                <label class="pl-1">{{ notification_frequency_option.name }}</label>
              </div>
            </div>
          </div>
          <div class="form-group form-inline">
            <label for="advisorNotificationFrequencyTypeId" class="col-4 align-self-start">
              <modal-link modal-id="Next Notification Date" :content-list="pageContent"></modal-link>Next Notification Date
            </label>
            <div>{{ nextRunDate }}</div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group form-inline">
            <modal-link modal-id="Organization" :content-list="pageContent"></modal-link>
            <label for="organization" class="control-label pt-1 pr-1 my-auto">Organization</label>
            <div class="w-75 pl-2">
<!-- VUE 2 implementation
              <treeselect
                v-model="advisorOrganizations"
                :multiple="true"
                :alwaysOpen="false"
                :show-count="false"
                :default-expand-level="0"
                :options="client_hierarchy"
                :append-to-body="true"
                :required="false"
                :clearable="false"
                :maxHeight="400"
                placeholder="Select"
              />
-->            
              <Treeselect
                :model-value="advisorOrganizations"
                @update:modelValue="onInput"
                is-independent-nodes="true"
                :is-single-select="false"
                :alwaysOpen="false"
                :show-count="false"
                :default-expand-level="0"
                :options="client_hierarchy"
                :append-to-body="true"
                :required="false"
                :clearable="true"
                :maxHeight="400"
                placeholder="Select"
              />

            </div>
          </div>
          <div v-show="displayIncludePrimaryClient" class="form-group form-inline">
            <input
              id="includePrimaryClient"
              name="includePrimaryClient"
              type="checkbox"
              true-value="Y"
              false-value="N"
              v-model="advisorConfiguration.includePrimaryClient"
            />
            <label class="pl-2">Include {{ advisorClientName }}</label>
          </div>
          <div class="form-group form-inline">
            <input
              id="onlySendChanges"
              name="onlySendChanges"
              type="checkbox"
              true-value="Y"
              false-value="N"
              v-model="advisorConfiguration.onlySendChanges"
            />
            <label class="pl-2"><modal-link modal-id="Only Send Changes" :content-list="pageContent"></modal-link>Only Send Changes</label>
          </div>
          <div class="d-none form-group form-inline">
            <modal-link modal-id="Report to Include" :content-list="pageContent"></modal-link>
            <label for="reportsToInclude" class="control-label pt-1 my-auto">Report to Include <br/>
            [leave blank for none]</label>
            <div class="w-50 pl-2">
              <multiselect
                v-model="advisorReports"
                :options="client_reports"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :max-height="250"
                select-label=""
                deselect-label=""
                selected-label=""
                placeholder="Select"
                label="clientReportTitle"
                name="reports-to-include"
                track-by="clientReportTitle"
                :preselect-first="false"
              >
                <template #option="props">
                  <span class="checkbox-label" @click.self="select(props.option)">
                    <input name="reports-to-include" class="mr-1" type="checkbox" v-model="props.option.checked" @focus.prevent />
                    {{ props.option.clientReportTitle }}
                  </span>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="advisor-border-right"></div>        
        <div class="col">
          <div class="advisor-border-right"></div>
          <div class="form-group form-inline">
            <label for="advisorNotificationFrequencyTypeId" class="col-2 align-self-start">
              <modal-link modal-id="Additional Email Address" :content-list="pageContent"></modal-link>Additional Email Addresses
            </label>
            <div class="w-75 pl-2">
              <multiselect
                v-model="advisorDestinationEmails"
                :options="advisor_destination_email_options"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :max-height="250"
                select-label=""
                deselect-label=""
                selected-label=""
                placeholder="None"
                label="emailAddress"
                name="advisor_destination_email"
                track-by="userId"
                :preselect-first="false"
                    >
                  <template #option="props">
                    <span class="checkbox-label" @click.self="select(props.option)">
                      <input name="advisor_destination_email" class="mr-1" type="checkbox" v-model="props.option.checked" @focus.prevent />
                      {{ props.option.firstName }} {{ props.option.lastName }} - {{ props.option.emailAddress }}
                    </span>
                  </template>
                </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="advisor-border"></div>
      <div class="row">
        <div class="notifications-to-receive">
          <div class="header-label">
            Threshold Notification Level
          </div>

          <div class="form-group form-inline" style="clear: both">
            <label for="advisorNotificationDataTypeId" class="col-2 align-self-start"><modal-link modal-id="Notifications to Receive" :content-list="pageContent"></modal-link>Notifications to Receive</label>
            <div class="col align-self-start">
              <div class="w-100 form-inline">
                <div class="notification-name"></div>
                <div class="more-than">Green</div>
                <div class="more-than">Yellow</div>
                <div class="more-than">Red</div>
              </div>
              <div class="w-100 form-inline" v-for="(notification_data_option, idxFrequencyOfNotifications) in advisorNotificationData" v-bind:key="idxFrequencyOfNotifications">
                <div class="notification-name form-inline">
                  <input
                    type="checkbox"
                    id="advisorNotificationDataTypeId"
                    name="advisorNotificationDataTypeId"
                    v-model="advisorNotificationData[idxFrequencyOfNotifications].enabled"
                    true-value="Y"
                    false-value="N"
                    @click="saveEnabledEvent($event, idxFrequencyOfNotifications)"
                  />
                  <label class="pl-2">{{ advisorNotificationData[idxFrequencyOfNotifications].name }}</label>
                </div>
                <input
                  v-model="advisorNotificationData[idxFrequencyOfNotifications].greenMoreThan"
                  type="text"
                  class="form-control more-than"
                  :name="'greenMoreThan_' + advisorNotificationData[idxFrequencyOfNotifications].advisorNotificationDataTypeId"
                />
                <input
                  v-model="advisorNotificationData[idxFrequencyOfNotifications].yellowMoreThan"
                  type="text"
                  class="form-control more-than"
                  :name="'yellowMoreThan_' + advisorNotificationData[idxFrequencyOfNotifications].advisorNotificationDataTypeId"
                />
                <input
                  v-model="advisorNotificationData[idxFrequencyOfNotifications].redMoreThan"
                  type="text"
                  class="form-control more-than"
                  :name="'redMoreThan_' + advisorNotificationData[idxFrequencyOfNotifications].advisorNotificationDataTypeId"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="advisor-border-right"></div>
        <CustomTimePeriod 
          :advisor-time-period="advisorTimePeriod" 
          :input-client-time-periods="client_time_periods"
          type="current"
          @save="saveCurrent"/>
        <div class="advisor-border-right"></div>
        <CustomTimePeriod 
          :advisor-time-period="advisorTimePeriod" 
          :input-client-time-periods="client_time_periods"
          type="previous"
          @save="savePrevious"/>
      </div>
      <div class="advisor-border"></div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary float-right pt-2 mr-4" @click="this.saveAdvisorConfiguration">
          <span>Save Configuration</span>
          <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
        </button>
      </div>
    </div>
    <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
      <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
    </div>    
  </div>
</template>
<script>
import UserService   from "../../services/user.service";
import AlertService  from "../../services/alert.service";
import ClientService  from "../../services/client.service";
import AdminService from "../../services/admin.service";
import AdvisorService from "../../services/advisor.service";
import ContentService from "../../services/content.service";
import CustomTimePeriod from "../../components/CustomTimePeriod.vue";
import CompanyMenu from "../../views/menu/CompanyMenu.vue";
import ViewMixins from "../../mixins/ViewMixins.vue";
import GlobalMixins from "../../mixins/GlobalMixins.vue";
import Multiselect from "vue-multiselect";
import $ from "jquery";
import Vue from "vue";
import moment from "moment";
Vue.prototype.moment = moment;

import Treeselect, { DirectionType, IconsType, OptionType, TreeselectValue } from 'vue-treeselectjs'
import 'vue-treeselectjs/dist/vue-treeselectjs.css'
import { reactive, toRaw } from 'vue'

export default {
  name: "Advisor",
  mixins: [ViewMixins,GlobalMixins],
  components: {
    Multiselect,
    CompanyMenu,
    CustomTimePeriod,
    Treeselect
  },
  props: {
    initialClientCode: {
      type: String,
      default: ""
    },    
    initialMode: {
      type: String,
      default: 'user-edit'
    },    
    initialClientName: {
      type: String,
      default: ""
    },    
    initialAdvisorConfigurationId: {
      type: Number,
      default: 0
    },    
  },
  data() {
    return {
      title: "Advisor",
      advisorClientCode: "",
      advisorClientName: "",
      mode: this.initialMode,
      pageNumber: 4,
      advisor_destination_email_options: [],
      notification_frequency_types: [],
      client_hierarchy: [],
      client_reports: [],
      client_time_periods: [],
      next_run_date_list: [],
      advisorTimePeriod: {
        advisorConfigurationId: 0,
        startDate: null,
        endDate: null,
        clientTimePeriodId: 0,
        customTimePeriodEnabled: "N",
        previousStartDate: null,
        previousEndDate: null,
        previousCustomTimePeriodEnabled: "N",
        previousClientTimePeriodId: 0
      },      
      advisorConfiguration: {
        advisorConfigurationId: 0,
        onlySendChanges: "N",
        includePrimaryClient: "Y",
        advisorNotificationFrequencyTypeId: 0
      },
      advisorReports: [],
      advisorNotificationData: [],
      advisorOrganizations: [],
      advisorDestinationEmails: [],
      startDate: "",
      tooltipConfigShow: ContentService.getWizardTooltip()     
    };
  },
  methods: {
    onInput: function (value) {
      this.advisorOrganizations = value;
    },
    saveCurrent: function (timePeriod) {
      this.advisorTimePeriod.startDate = timePeriod.startDate;
      this.advisorTimePeriod.endDate = timePeriod.endDate;
      this.advisorTimePeriod.clientTimePeriodId = Number(timePeriod.clientTimePeriodId);
      this.advisorTimePeriod.customTimePeriodEnabled = timePeriod.customTimePeriodEnabled;
    },
    savePrevious: function (timePeriod) {
      this.advisorTimePeriod.previousStartDate = timePeriod.startDate;
      this.advisorTimePeriod.previousEndDate = timePeriod.endDate;
      this.advisorTimePeriod.previousClientTimePeriodId = Number(timePeriod.clientTimePeriodId);
      this.advisorTimePeriod.previousCustomTimePeriodEnabled = timePeriod.customTimePeriodEnabled;
    },
    saveAdvisorTimePeriodType: function () {},
    saveEnabledEvent: function (event, index) {
      this.advisorNotificationData[index].enabled = event.target.checked ? "Y" : "N";
    },
    dataVerified: function (){
      AlertService.closeAlert();
       let dataVerified = true;
       if (this.advisorTimePeriod.previousClientTimePeriodId == "-2"){
            AdminService.displayErrorMessage({ text: "Please select a value for the previous time period " });  
            dataVerified = false;
            return dataVerified;
       }
       if (this.advisorTimePeriod.clientTimePeriodId == "-2"){
            AdminService.displayErrorMessage({ text: "Please select a value for the time period " });  
            dataVerified = false;
            return dataVerified;
       }
       if (this.advisorTimePeriod.customTimePeriodEnabled == "Y" && this.advisorTimePeriod.startDate == null ){
            AdminService.displayErrorMessage({ text: "Please select a custom start date for time period " });  
            dataVerified = false;
            return dataVerified;
       }
       if (this.advisorTimePeriod.customTimePeriodEnabled == "Y" && this.advisorTimePeriod.endDate == null){
            AdminService.displayErrorMessage({ text: "Please select a custom end date for time period " });  
            dataVerified = false;
            return dataVerified;
       }
       if (this.advisorTimePeriod.previousCustomTimePeriodEnabled == "Y" && this.advisorTimePeriod.previousStartDate == null){
            AdminService.displayErrorMessage({ text: "Please select a custom start date for previous time period " });  
            dataVerified = false;
            return dataVerified;
       }
       if (this.advisorTimePeriod.previousCustomTimePeriodEnabled == "Y" && this.advisorTimePeriod.previousEndDate == null){
            AdminService.displayErrorMessage({ text: "Please select a custom end date for previous time period " });  
            dataVerified = false;
            return dataVerified;
       }
       for (let notificationData of this.advisorNotificationData) {
          if (isNaN(notificationData.greenMoreThan) || isNaN(notificationData.yellowMoreThan) || isNaN(notificationData.redMoreThan)
              ){
            AdminService.displayErrorMessage({ text: "Notification Threshold fields should be numeric only " });  
            dataVerified = false;
            return dataVerified;
          }

          if (notificationData.advisorNotificationDataTypeName != "Satisfaction Gap Changes" && notificationData.advisorNotificationDataTypeName != "Emotions Gap Changes") {
                let errorText = "For " + notificationData.advisorNotificationDataTypeName + " the system will send you notifications when the metric is decreasing, which indicates worse performance. The "
                if (Number(notificationData.greenMoreThan) < Number(notificationData.yellowMoreThan)){
                  errorText += " threshold for yellow should be less than green "
                  AdminService.displayErrorMessage({ text:  errorText});  
                  dataVerified = false;
                  return dataVerified;
                }
                if (Number(notificationData.yellowMoreThan) < Number(notificationData.redMoreThan)){
                  errorText += " threshold for red should be less than yellow "
                  AdminService.displayErrorMessage({ text: errorText });  
                  dataVerified = false;
                  return dataVerified;
                }
                if (Number(notificationData.greenMoreThan) < Number(notificationData.redMoreThan)){
                  errorText += " threshold for green should more than red & yellow"
                  AdminService.displayErrorMessage({ text: errorText });  
                  dataVerified = false;
                  return dataVerified;
                }
          } else {
                let errorText = "For " + notificationData.advisorNotificationDataTypeName + " the system will send you notifications when the gap is increasing, which indicates worse performance. The "
                if (Number(notificationData.greenMoreThan) > Number(notificationData.yellowMoreThan)){
                  errorText += " threshold for yellow should be greater than green "
                  AdminService.displayErrorMessage({ text:  errorText});  
                  dataVerified = false;
                  return dataVerified;
                }
                if (Number(notificationData.yellowMoreThan > notificationData.redMoreThan)){
                  errorText += " threshold for red should be greater than yellow "
                  AdminService.displayErrorMessage({ text: errorText });  
                  dataVerified = false;
                  return dataVerified;
                }
                if (Number(notificationData.greenMoreThan > notificationData.redMoreThan)){
                  errorText += " threshold for green should be less than red & yellow "
                  AdminService.displayErrorMessage({ text: errorText });  
                  dataVerified = false;
                  return dataVerified;
                }
          }

       }
      return dataVerified;
    },
    saveAdvisorConfiguration: function () {
      if (this.dataVerified()){
        if (this.advisorOrganizations.length == 0){
          this.advisorConfiguration.includePrimaryClient = "Y";
        }
        this.$root.processServerRequest("Saving advisor configuration");
        let userId = this.isAdminEdit ? this.advisorConfiguration.userId : UserService.getUserId();
        AdvisorService.saveAdvisorConfiguration(userId, this.advisorClientCode, this.advisorConfiguration, this.advisorNotificationData, 
            this.advisorReports, this.advisorOrganizations, this.advisorDestinationEmails, this.advisorTimePeriod,
            this.saveAdvisorConfigurationCallback, this);
      }
    },
    saveAdvisorConfigurationCallback: function (response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message, duration: 1000 });
        this.advisorTimePeriod = response.data.data.advisor_time_period;
        this.advisorConfiguration = response.data.data.advisor_configuration;
        this.advisorNotificationData = response.data.data.advisor_notification_data;
        this.advisorOrganizations = response.data.data.advisor_organizations;
        this.advisorDestinationEmails = response.data.data.advisor_destination_emails;
        this.advisorReports = response.data.data.advisor_reports;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },     
    getOptionsCallback: function (response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.advisorTimePeriod = response.data.data.advisor_time_period;
        this.advisorConfiguration = response.data.data.advisor_configuration;
        this.advisorNotificationData = response.data.data.advisor_notification_data;
        this.advisorOrganizations = response.data.data.advisor_organizations;
        this.advisorDestinationEmails = response.data.data.advisor_destination_emails;
        this.advisorReports = response.data.data.advisor_reports;
        this.client_reports = response.data.data.client_reports;
        this.client_time_periods = response.data.data.client_time_periods;
        this.client_hierarchy = response.data.data.client_hierarchy;
        this.advisor_destination_email_options= response.data.data.advisor_destination_email_options;
        this.notification_frequency_types = response.data.data.notification_frequency_types;
        this.next_run_date_list = response.data.data.next_run_date_list;
        $.extend(true, this.advisor_destination_email_options, this.advisorDestinationEmails);
        AdminService.displayInfoMessage({ text: response.data.message, duration: 1000 });

        this.client_hierarchy = this.client_hierarchy.filter(function(inputClient) {
        let client = toRaw(inputClient);
          return typeof client.id != "undefined";
        });
        let vm = this
        this.client_hierarchy = this.client_hierarchy.map(function(inputClient) {
          let client = toRaw(inputClient);
          return vm.resetClientForOptions(client);        
        });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    // Vue 3 migration - after the migration we will need to recode the server side to return the correct data.
    // once we do that we will be able to remove these functions
    resetClientForOptions: function(client) {
      let resetClientForOptionsRecursive = function(client) {
        let updatedClient = {};
        updatedClient.value = client.id;
        if (typeof client.children === "undefined") {
          updatedClient.children = [];
        } else {
          updatedClient.children = client.children.map(resetClientForOptionsRecursive);
        }
        if (typeof client.label !== "undefined") {
          updatedClient.name = client.label;
        }
        return updatedClient;
      };

      return resetClientForOptionsRecursive(client);
    },  
    loadData: async function () {
      if (this.mode == "user-edit") {
        this.advisorClientName = this.clientName;
        this.advisorClientCode = this.clientCode;
      } else {
        this.advisorClientName = this.initialClientName;
        this.advisorClientCode = this.initialClientCode;
      }
      this.advisorNotificationData = [];
      this.advisorOrganizations = [];
      this.advisorDestinationEmails = [];

      this.setDisplayView(false);
      this.$root.processServerRequest("Retrieving advisor options");
      await AdvisorService.getOptions(this.advisorClientCode, this.initialAdvisorConfigurationId, this.getOptionsCallback, this);
      this.setDisplayView(true);
    },
  },
  created() {},
  mounted() {
    if (this.clientId != 0) {
      this.loadData();
    }    
    this.$root.setShowMenuIcon(true);
  },
  computed: {
    isAdminEdit: function(){
      return this.mode == 'admin-edit';
    },
    displayIncludePrimaryClient: function() {
      return this.advisorOrganizations.length > 0;
    },
    nextRunDate: function() {
      let dateObject = this.next_run_date_list.find(x => x.typeId == this.advisorConfiguration.advisorNotificationFrequencyTypeId);
      if (dateObject.date == "" || dateObject.date == null){
        return "";
      }
      return new Date(dateObject.date).toLocaleDateString("en-US", { weekday: "long", year: "numeric", month: "short", day: "numeric" });
    },
  },
  watch: {
    clientId: function () {
      this.mode = "user-edit";
      this.loadData();
    }  
  },
};
</script>