<template>
  <vue-modal :key="refreshModal" :ref="modalName" :name="modalName" @opened="setHeight" :draggable="true" :adaptive="true" :resizable="true" :width="1068" height="auto">
    <div id="preview-report-modal" class="modal-content" style="overflow: auto;">
      <div class="modal-header">
        <h5 class="modal-title">Preview Report</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body mx-auto">
        <div v-html="this.previewReportHtml"></div>
      </div>
      <div class="modal-footer">
        <button v-show="displayPrintButton" type="button" class="d-none btn btn-primary" @click="initializePrintReportToPDF">
          <span class="align-text-bottom"><font-awesome-icon :icon="['fa', 'images']" /><span style="padding-left: 5px">Print to PDF</span></span>
        </button>
        <button v-show="displayPrintButton" type="button" class="btn btn-primary" @click="initializePrintReportToPPTX">
          <span class="align-text-bottom"><font-awesome-icon :icon="['fas', 'file-powerpoint']" /><span style="padding-left: 5px">Print to PPTX</span></span>
        </button>
        <button v-show="displaySaveButton" type="button" class="d-none btn btn-primary" @click="initializeSaveReportToPDF">
          <span class="align-text-bottom"><font-awesome-icon :icon="['fa', 'floppy-disk']" /><span style="padding-left: 5px">Save to PDF</span></span>
        </button>
        <button v-show="displaySaveButton" type="button" class="btn btn-primary" @click="initializeSaveReportToPPTX">
          <span class="align-text-bottom"><font-awesome-icon :icon="['fa', 'floppy-disk']" /><span style="padding-left: 5px">Save to PPTX</span></span>
        </button>
        <button type="button" class="btn btn-primary ml-2" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";

export default {
  name: "PreviewReport",
  components: {},
  mixins: [],
  methods: {
    initializePrintReportToPDF: function(){
      this.$refs[this.modalName].visible = false;
      this.$emit("print-to-pdf");
    },
    initializeSaveReportToPDF: function(){
      this.$refs[this.modalName].visible = false;
      this.$emit("save-to-pdf");
    },
    initializePrintReportToPPTX: function(){
      this.$refs[this.modalName].visible = false;
      this.$emit("print-to-pptx");
    },
    initializeSaveReportToPPTX: function(){
      this.$refs[this.modalName].visible = false;
      this.$emit("save-to-pptx");
    },
    setHeight: function(){
      var $window = jQuery(window);
      let viewport_height = $window.height();
      $("#preview-report-modal").height(viewport_height);
    },
    closeModal: function() {
      this.$refs[this.modalName].visible = false;
      //this.$parent.displayPreviewModal=false;
      this.refreshModal = !this.refreshModal;
    }
  },
  props: {
    displayPrintButton: {
      type: Boolean,
      default: true
    },
    displaySaveButton: {
      type: Boolean,
      default: false
    },
    modalName: {
      type: String,
      default: "render-client"
    },
    previewReportHtml: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      returnMessage: "",
      refreshModal: true
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {}
};
</script>
<style lang="scss">
@import "../sass/_print_report.scss";
#preview-report-modal{
  .print-report{
      border: 2px solid #f2f2f2;
      margin: 15px 0px !important;
      padding: 15px !important;
  }
  .page, .front-page, .base-page, .end-page{
      height: auto !important;
      min-height: auto !important;
  }
  .print-report-front-page-image{
      width: 119px;
      height: 119px;
      background-image: url('../assets/img/print-report/wRatings-2021-GoForTheW-v2.png') !important;
      background-repeat: no-repeat;
      background-size: 100% 100%;
  }
  .print-report-footer-image{
      height: 30px;
      width: 135px;
      background-image: url('../assets/img/print-report/The-W-Report-transparent-v2.png') !important;
      background-repeat: no-repeat;
      background-size: 100% 100%;
  }  
  .end-page{
    .print-report-footer-image{
      height: 200px !important;
      width: 750px !important;
      background-image: url('../assets/img/print-report/wRatings-2021-transparent-v2.png') !important;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }  
  }
}
</style>
