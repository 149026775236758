<template>
  <vue-modal ref="import-metric-file" name="import-metric-file" :width="398" height="auto"  :scrollable="true"  @opened="initializeMetricGroup">
    <form id="ImportMetricsFormId" name="form" @submit.prevent="importFile">
      <div class="container pl-3 pr-3">
        <div class="form-group form-row p-2">
          <h5 class="font-weight-bold">Import Metric Data</h5>
        </div>
        <div v-show="this.modalReturnMessage != ''" class="form-group form-row p-2 text-success">
          <span :class="modalReturnMessageClass"> {{ modalReturnMessage }}</span>
        </div>
        <div v-show="rowSelected" class="form-group form-row pt-1">
          <input type="radio" v-model="mode" name="save" value="existing" />
          <span class="pl-1">Import into selected metric group: {{ initialMetricName }}</span>
        </div>
        <div class="form-group form-row pt-1">
          <input type="radio" v-model="mode" name="save" value="new" class="pr-1"/>
          <span class="pl-1">Import As New Metric Group</span>
        </div>
        <div v-show="isNewImport" class="form-row pt-1">
          <div class="form-group form-inline w-100">
            <label for="metricGroupName" class="mr-1">Metric Name</label>
            <input v-model="metricGroup.name" id="metricGroupName" name="metricGroupName" type="text" class="form-control required w-75 required" />
          </div>
        </div>
        <div v-show="isNewImport" class="form-row pt-1">
          <div class="form-group form-inline w-100">
            <label for="metricType" class="mr-1">Metric Type</label>
            <select id="metricType" name="metricType" class="custom-select required col-9" v-model="metricGroup.metricTypeId">
              <option v-for="(option, idxMetricDisplayTypeOptions) in this.metricTypeOptions" v-bind:value="option.id" v-bind:key="idxMetricDisplayTypeOptions">
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>         
        <div v-show="isNewImport" class="form-row pt-1">
          <div class="form-group form-inline w-100">
            <label for="metricDisplayType" class="mr-1">Metric Display Type</label>
            <select id="metricDisplayType" name="metricDisplayType" class="custom-select required col-9" v-model="metricGroup.metricDisplayTypeId">
              <option v-for="(option, idxMetricDisplayTypeOptions) in this.metricDisplayTypeOptions" v-bind:value="option.id" v-bind:key="idxMetricDisplayTypeOptions">
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-show="isNewImport" class="form-row pt-1">
          <div class="form-group form-inline w-100">
            <label for="calculatedGrowthRate" class="mr-1">Calculate Growth Rate</label>
            <div class="col align-self-start form-inline">
              <div class="w-30 pl-1" v-for="(calculate_growth_rate_option, idxCalculateGrowthRate) in calculate_growth_rate_options" v-bind:key="idxCalculateGrowthRate">
                <input
                  type="radio"
                  id="calculatedGrowthRate"
                  name="calculatedGrowthRate"
                  v-model="metricGroup.calculatedGrowthRate"
                  :value="calculate_growth_rate_option.value"
                  @click="saveCalculatedGrowthRate"
                />
                {{ calculate_growth_rate_option.name }}
              </div>
            </div>
          </div>
        </div>
        <div v-show="isNewImport" class="form-row pt-2">
          <div class="form-group form-inline"><label for="Outliers" class="mr-1">Rank Metrics Outliers</label></div>
          <div class="form-group form-inline pl-3">
            <label for="minimumOutliers" class="mr-1 ml-1">Min</label>
            <input v-model="metricGroup.minimumOutliers" id="minimumOutliers" name="minimumOutliers" type="text"
              class="form-control"  />
          </div>
          <div class="form-group form-inline">
            <label for="maximumOutliers" class="mr-1 ml-1">Max</label>
            <input v-model="metricGroup.maximumOutliers" id="maximumOutliers" name="maximumOutliers" type="text"
              class="form-control " />
          </div>
        </div>
        <div class="form-group form-row pt-1">
          <span class="pr-2">Select a file to upload</span>
          <input ref="importMetricUpload" type="file" @change="onFileChange" />
        </div>
        <div class="form-group form-row pt-1 d-flex justify-content-end">
          <button type="submit" class="btn btn-primary float-right ml-2">
            <span>Import File</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-4"
            @click="
              this.$refs['import-metric-file'].visible = false;
              $router.go(0);
            "
          >
            Close
          </button>
        </div>
      </div>
    </form>
  </vue-modal>
</template>

<script>
import $ from "jquery";

import ContentMixins from "../mixins/ContentMixins.vue";
import ViewMixins from "../mixins/ViewMixins.vue";

import AdminService from "../services/admin.service";
import MetricsService from "../services/metrics.service";
import FormService from "../services/form.service";

export default {
  name: "ImportMetrics",
  components: {},
  mixins: [ContentMixins, ViewMixins],

  methods: {
    initializeMetricGroup(){
      if (this.initialMode == "existing") {
        this.metricGroup = JSON.parse(JSON.stringify(this.initialMetricGroup));
      }
    },    
    getMetricTypes: function () {
      MetricsService.getMetricTypes(this.getMetricTypesCallback, this);
    },
    getMetricDisplayTypes: function () {
      MetricsService.getMetricDisplayTypes(this.getMetricDisplayTypesCallback, this);
    },
    getMetricTypesCallback: function (response) {
      this.metricTypeOptions = response.data;
    },
    getMetricDisplayTypesCallback: function (response) {
      this.metricDisplayTypeOptions = response.data;
    },
    getDownloadFilename() {
      return "Metric Import Template";
    },
    onFileChange: function () {
      this.importMetricImportFile = this.$refs.importMetricUpload.files[0];
    },
    async importFile() {
      if (this.isNewImport) {
        this.setupImportMetricsForm();
        if (!this.importMetricsForm.valid()) {
          return false;
        }
      }
      if (this.importMetricImportFile) {
        this.setModalLoading(true);
        this.modalReturnMessage = "Importing metric data please stand by.....";
        MetricsService.importFile(this.importMetricImportFile, this.metricGroup, this.mode, this.importFileCallback, this);
      } else {
        AdminService.displayErrorMessage({ text: "Please choose a file to upload." });
      }
    },
    importFileCallback: function (response) {
      this.$root.processServerResponse();
      this.setModalLoading(false);
      let statusCode = response.data.statusCode;
      this.modalReturnMessage = response.data.message;
      if (statusCode == 200) {
        this.modalReturnMessageClass = "text-success";
        this.$emit("reload");
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        this.modalReturnMessageClass = "text-danger";
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    saveCalculatedGrowthRate: function () {
      this.metricGroup.calculatedGrowthRate = event.target.value;
    },
    setupImportMetricsForm() {
      this.importMetricsForm = $("#ImportMetricsFormId");
      FormService.setupForm(this.importMetricsForm);
      this.validator = this.importMetricsForm.validate(FormService.getBaseValidator());
    },
  },
  props: {
    initialMetricName: {
      type: String,
      default: "",
    },
    initialMetricGroup: {
      type: Object,
      default: function () {
        return {};
      },
    },
    initialMode: {
      type: String,
      default: "",
    },
    rowSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalReturnMessageClass: "text-success",
      modalReturnMessage: "",
      metricDisplayTypeOptions: [],
      metricTypeOptions: [],
      mode: "new",
      metricGroup: MetricsService.getDefaultMetricGroup(),
      validator: null,
      importMetricsForm: null,
    };
  },
  computed: {
    calculate_growth_rate_options: function () {
      return AdminService.getCalculateGrowthRateOptions();
    },
    isNewImport: function () {
      return this.mode == "new";
    },
  },
  created() {},
  mounted() {
    this.getMetricTypes();
    this.getMetricDisplayTypes();
  },
  watch: {
    initialMode: function () {
      this.mode = this.initialMode;
    },
    initialMetricGroup: function () {
      if (this.initialMode == "existing") {
        this.metricGroup = JSON.parse(JSON.stringify(this.initialMetricGroup));
      }
    },
  },
};
</script>
