<template>
  <div :class="getClass(dashboardLayoutCard.dashboardCardTypeCode, dashboardLayoutCard.sequenceNumber)">
    <div class="dashboard-header">
      <div class="dashboard-title">
        <div class="dashboard-title-details">
          <a href="/analytics/predictive" @click="$parent.logViewDetailsEvent(title)">            
            {{ title }}
          </a>
          <smart-center-link v-if="displayDashboard" page="Competitive Dashboard" location="Predictive Analytics" />
        </div>
        <div class="dashboard-save-dashboard-card"  @click="$modal.show(modalName)"> 
            Save for Report
            <span class="m-1">
              <font-awesome-icon :icon="['fa', 'floppy-disk']" />
            </span>
        </div>
      </div>
      <div class="dashboard-widgets dashboard-contributions" style="flex-direction: column;justify-content: space-between;">
        <div v-if="!isPricingPower"  class="d-flex w-100" >
          <dashboard-card-dropdown label="Metric" :read-only="readOnly" :options="inputMetricOptions" :default-value="metricId" @change="saveMetric"></dashboard-card-dropdown>
        </div>
        <div v-if="!isPricingPower" class="d-flex w-100" style="justify-content: flex-end;">
          <div class="d-flex pr-2">
            <div class="very-strong-correlation-square dashboard-predictive-analytics-square"></div>
            <div class="pl-1">Very Strong</div>
          </div>
          <div class="d-flex pr-2">
            <div class="strong-correlation-square dashboard-predictive-analytics-square"></div>
            <div class="pl-1">Strong</div>
          </div>
          <div class="d-flex pr-2">
            <div class="mild-correlation-square dashboard-predictive-analytics-square"></div>
            <div class="pl-1">Mild</div>
          </div>
        </div>
      </div>
      <dashboard-card-expand :dashboard-id="dashboardId" />
    </div>
    <div class="dashboard-divider"></div>
    <dashboard-card-skeleton v-if="!displayDashboard" />
    <div class="dashboard-card-body"  v-show="displayDashboard" style="height: 250px">
      <div v-show="displayDashboard" id="analytics-contributions-container" 
          class="predictive-analytics-echart" :style="dashboardChartStyle">
            <div class="contribution"
                v-for="(contributionChart, idxContributionChart) in this.contributionChartList"
                :key="idxContributionChart">
                        <predictive-analytics-contribution-chart 
                        :prefix="prefix"
                        :is-saved-card="clientChart.clientChartId != 0" 
                        :is-dashboard-card="true"
                        :contribution-chart="contributionChart" 
                        v-if="displayDashboard"/>

            </div>
            <div v-show="this.contributionChartList.length == 0" class="no-data-for-chart-container contribution w-100  align-middle">
              <div v-show="this.contributionChartList.length == 0" class="no-data-for-chart mx-auto">
                Select a metric in the drop-down menu above <br /> 
                to analyze your growth contribution. <br /> <br /> 
                If no metrics exist, contact <br />
                your wRatings Research Advisor <br />
                to add them for you.
              </div>
            </div>
      </div>
      <div id="performance-trends-mini">
        <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
      </div>       
    </div>
    <dashboard-predictive-analytics-contributions-modal
      ref="modalPredictiveAnalytics"
      :modal-id="dashboardId"
      :modal-title="title"
      :modal-contribution-chart-list="contributionChartList"
      :client-chart="this.clientChart" 
      :input-is-pricing-power="isPricingPower"
      :dashboard-card-report-page-type-name="reportTypeName"      
      :modal-chart-options="options"
    />
    <vue-modal :ref="modalName" :name="modalName" :width="350" :height="200">
      <save-chart :modal-name="modalName" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.updateClientCharts" @closeModal="closeModal(this.modalName)"></save-chart>
    </vue-modal>
  </div>
</template>

<script>
import DashboardMixins from "../../mixins/DashboardMixins.vue";
import DashboardService from "../../services/dashboard.service";
import AdminService from "../../services/admin.service";
import MetricsService from "../../services/metrics.service";
import PrintAndCopyService from "../../services/print.and.copy.service";
import ReportService from "../../services/report.service";
import EChartsService from "../../services/echarts.service";
import DashboardPredictiveAnalyticsContributionsModal from "../../modals/DashboardPredictiveAnalyticsContributionsModal.vue";
import SaveChart from "../chart/SaveChart.vue";
import PredictiveAnalyticsContributionChart from "../../views/echarts/PredictiveAnalyticsContributionChart.vue";
import DashboardCardDropdown from "./DashboardCardDropdown";
import html2canvas from "html2canvas";
import $ from "jquery";
import DashboardCardSkeleton from "../../components/skeleton/DashboardCardSkeleton.vue";

export default {
  name: "DashboardPredictiveAnalyticsContributions",
  mixins: [DashboardMixins],
  components: { DashboardPredictiveAnalyticsContributionsModal,SaveChart,PredictiveAnalyticsContributionChart,DashboardCardDropdown,DashboardCardSkeleton },
  data() {
    return {
      modalName: "predictive-analytics-save-chart-confirmation",     
      contributionChartList: [],
      metricId: "",
      useMetricGrowth: false,
      numberOfRows: 6,
      isPricingPower: false,
      saveMetricUserSetting: false,
      prefix: "p",
      title: "Pricing Power Opportunities"
    };
  },
  props: {
    inputIsPricingPower: {
      type: Boolean,
      default: true
    },
    entryClientChart: {
      type: Object,
      default: function () {
        return {};
      },
    },
    inputMetricId: {
      type: String,
      default: ""
    },
    inputMetricOptions: {
      type: Array,
      default: function() {
        return [];
      }
    },
  },
  computed: {},
  mounted() {
    // eslint-disable-next-line no-console
    //console.log("DashboardPredictiveAnalyticsContributions::mounted");
  },
  created() {},
  methods: {
    setChartDetails(){
      if (this.isPricingPower == true){        
        this.prefix = "pricing-power";
        this.title = "Pricing Power Opportunities";
        this.chartTitle = "Pricing Power Opportunities";
        this.chartTypeName = "dashboard-predictive-analytics";
        this.containerId = "pricing-power-contribution-snap-id";
    	  this.reportTypeName = "Dashboard - Contribution";
        this.modalName = "predictive-analytics-save-chart-confirmation";
        this.saveMetricUserSetting = false;
      } else {
        this.prefix = "metric";
        this.title = "Growth Contribution";
        this.chartTitle = "Growth Contribution";
        this.chartTypeName = "dashboard-metric-contributions";
        this.containerId = "metric-contribution-snap-id";
    	  this.reportTypeName = "Dashboard - Metric Contributions";
        this.modalName = "metric-contributions-save-chart-confirmation";
        this.saveMetricUserSetting = true;
      }
    },
    clearData() {
      // eslint-disable-next-line no-console
      //console.log("DashboardPredictiveAnalyticsContributions::clearData " );
      this.displayDashboard = false;
      this.contributionChartList = [];
    },
    processSavedChartCriteria(criteria) {
      let criteriaParameters = criteria.split("=");
      let isMetric = criteriaParameters[0] == "metricGroupId" ? true : false;
  		if (isMetric){
        this.metricId = criteriaParameters[1];
  		}
      let isUseMetricGrowth = criteriaParameters[0] == "useMetricGrowth" ? true : false;
  		if (isUseMetricGrowth){
        this.useMetricGrowth = criteriaParameters[1];
  		}      
      let isPricingPower = criteriaParameters[0] == "isPricingPower" ? true : false;
  		if (isPricingPower){
        this.isPricingPower = criteriaParameters[1].toLowerCase() === "true";
  		}      
    },    
    buildUrlParams(client_id) {
      let params = this.buildBaseUrlParams();
      params.append("metricGroupId",this.metricId);
      params.append("useMetricGrowth",this.useMetricGrowth);      
      params.append("isPricingPower",this.isPricingPower);      
      return params;
    },  
    initialLoadData: async function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardPredictiveAnalyticsContributions::initialLoadData inputIsPricingPower: " + this.inputIsPricingPower);
      if (this.autoGenerateChartRun){
          this.processAutoGenerateChart();
      }  else {
/*        
        this.metricId = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_METRIC_CONTRIBUTIONS_METRIC_SETTING_TYPE, 
            this.metricId, this.inputMetricOptions);
*/            
      }    
      this.clearData();
      this.parseMetric();
      // eslint-disable-next-line no-console
      //console.log("DashboardPredictiveAnalyticsContributions::initialLoadData metricId: " + this.metricId + "  this.timePeriodId: " + this.timePeriodId);
      if (this.timePeriodId != "") {
        this.displayDashboard = false;
        DashboardService.getPredictiveAnalytics(this.metricId, this.timePeriodId, this.useMetricGrowth, this.saveMetricUserSetting, this.getPredictiveAnalyticsCallback, this);
      } else {
        this.displayDashboard = true;
      }
      //this.setChartDetails();
    },
    loadData: function() {
      // eslint-disable-next-line no-console
      //console.log("DashboardPredictiveAnalyticsContributions::loadData");
      if (this.autoGenerateChartRun){
        this.initialLoadData();
      } else {
        this.isPricingPower = this.inputIsPricingPower;
        this.metricId = this.inputMetricId;
/*        
        this.metricId = DashboardService.getDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_METRIC_CONTRIBUTIONS_METRIC_SETTING_TYPE, 
            this.inputMetricId, this.inputMetricOptions);
*/            
        this.displayDashboard = true; 
      }
      this.setChartDetails();
    },
    getPredictiveAnalyticsCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        if (!this.autoGenerateChartRun){
          this.setupClientChart();
        }      
        this.contributionChartList = [];
        let tempContributionChartList = JSON.parse(JSON.stringify(response.data.data.contributionChartList));
        this.contributionChartList = EChartsService.setContributionChartList(tempContributionChartList);

        if (this.contributionChartList[0].charts.length > 1){ 
          if (this.isPricingPower == true){
            this.contributionChartList[0].charts.splice(1, 1);
          } else {
            this.contributionChartList[0].charts.splice(0, 1);
          }
        } else if (this.contributionChartList[0].charts.length == 1){ 
          if (this.isPricingPower == false){
            this.contributionChartList = [];
          }
        }
        this.displayDashboard = true;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    saveMetric: function(value) {
      this.metricId = value;
      this.useMetricGrowth = false;
      DashboardService.setDashboardSetting(this.$parent.defaultDashboardValues, DashboardService.DASHBOARD_METRIC_CONTRIBUTIONS_METRIC_SETTING_TYPE, this.metricId, this.metric_options);
      this.initialLoadData();
    },    
    async saveReportPage(containerId, reportTypeName) {
      if (this.clientChart.clientChartId != 0   && this.isGenerateReportRunning()) {

        let reportPageChart = $("#" + containerId);
        let reportPageChartObject = reportPageChart[ 0 ];        
        let imageReportPageChartContent = await this.createChartImage(reportPageChartObject, false, true, 3); 
        await ReportService.saveReportPage(imageReportPageChartContent, reportTypeName, this.clientChart, this.saveReportPageCallback, this);
        await ReportService.saveReportPageFile(this.imageUrl, "png", reportTypeName, this.clientChart, this.saveReportPageFileCallback, this);
      }
    },    
    saveReportPageCallback(){},
    saveReportPageFileCallback(){},
    async createChartImage(chartContainer, keyOnly = false, includeRemoveBox = true, scale = 10, imageClass = "pdf-content-image-width"){
      let vm = this;
      chartContainer = PrintAndCopyService.getChartContainer(chartContainer, keyOnly, includeRemoveBox);
      
      await html2canvas(chartContainer, PrintAndCopyService.getHTML2CanvasOptions(false,scale)).then(async function (canvas) {
        vm.imageUrl = canvas.toDataURL();
      });
      let myImage = $('<img/>');
      myImage.attr('src', this.imageUrl);
      myImage.attr('class', imageClass);

      let imageReportPageChartContent = myImage
          .wrapAll("<div>")
          .parent()
          .html();
      return imageReportPageChartContent;
    },
  },
  watch: {
    inputMetricId: function() {
      this.metricId = this.inputMetricId;
      this.initialLoadData();
    },
    timePeriodId: function() {
      if (this.timePeriodId != "") {
        this.initialLoadData();
      }
    }
  }
};
</script>
