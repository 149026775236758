<template>
  <div class="container">
    <form id="ForgotPasswordFormId" name="form" @submit.prevent="sendForgotPasswordEmail">
      <div class="container">
        <div class="form-group form-row align-items-center password-message">Please enter your email address and we will send you a link to reset your password</div>
        <div class="form-group no-gutters">
          <label for="email" class="col-2">Email</label>
          <input v-model="email" id="email" name="email" class="form-control col-12 required" type="text" emailCheck="emailCheck"/>
        </div>
        <div role="group" class="w-100 pb-3">
          <button class="btn btn-primary btn-block" :disabled="this.$root.getLoading()">
            <span>Send</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm ml-2"></span>
          </button>
        </div>
        <a href="/login" class="back-to-login-link">Back to Sign In</a>      
      </div>
    </form>
  </div>
</template>
<script>
import $ from "jquery";

import AuthService from "../services/auth.service";
import ErrorService from "../services/error.service";
import AdminService from "../services/admin.service";
import FormService from "../services/form.service";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      username: "",
      validator: null,
      forgotPasswordForm: null
    };
  },
  methods: {
    sendForgotPasswordEmailCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      this.displayResponse = true;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message, group: "auth" });
        this.$refs['forgot-password'].visible = false;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async sendForgotPasswordEmail() {
      if (!this.forgotPasswordForm.valid()) {
        return false;
      } else {
        this.$root.processServerRequest();
        const params = new URLSearchParams();
        params.append("email", this.email);
        await AuthService.sendForgotPasswordEmail(params, this.sendForgotPasswordEmailCallback).catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("ForgetPassword.sendForgotPasswordEmail", error, "admin", this);
          AdminService.displayErrorMessage({ text: error.message, group: "auth" });
        });
      }
    },
    setupForgotPasswordForm() {
      this.forgotPasswordForm = $("#ForgotPasswordFormId");
      FormService.setupForm(this.forgotPasswordForm);
      this.validator = this.forgotPasswordForm.validate(FormService.getBaseValidator());
    }
  },
  umnounted() {},
  computed: {},
  mounted() {
    this.setupForgotPasswordForm();
  },
  watch: {},
  props: {}
};
</script>
