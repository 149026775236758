<template>
  <div v-if="isAdmin" class="panel-admin-body">
    <div class="title">{{ title }}</div>
    <div class="container survey-list">
      <div class="row">
        <div class="button-group">
          <div class="button-group-title">Survey</div>
          <div class="button-row survey-button-row">          
            <div class="pr-2 d-inline" >
              <router-link :to="{ name: 'create-survey' }">
                <button type="button" class="btn btn-success" id="CreateSurveyButton">
                  Create
                  <span class="ml-auto">
                    <font-awesome-icon :icon="['fa', 'plus-circle']" />
                  </span>
                </button>
              </router-link>
            </div>
            <div class="pr-2 d-inline">
              <button type="button" 
                :class="[!this.table.rowSelected ? 'btn btn-primary disable-link ' : 'btn btn-primary']"
                id="DuplicateSurveyButton" :disabled="!table.rowSelected" @click="$modal.show('copy-survey')">
                Copy
                <span class="ml-auto">
                  <font-awesome-icon :icon="['fa', 'copy']" />
                </span>
              </button>
            </div>
            <div class="pr-2 d-inline">
              <a href="#" @click="displayDeleteConfirmation(deleteSurvey,table.rowSelectedId,'Delete Confirmation Survey','Are you sure you want delete the survey ?')"
                :class="[!this.table.rowSelected ? 'disable-link ' : '']"
              >
                <button type="button" class="btn btn-primary" id="DeleteSurveyButton" :disabled="!table.rowSelected">
                  Delete
                  <span class="ml-auto">
                    <font-awesome-icon :icon="['fa', 'minus-circle']" />
                  </span>
                </button>
              </a>
            </div>
            <div class="pr-2 d-inline">
                <a :class="[!this.table.rowSelected ? 'disable-link pr-2' : 'pr-2']" href="#" @click="$modal.show('survey-locale-config')">
                  <button type="button" class="btn btn-primary" :disabled="!table.rowSelected">
                      Add Locale
                      <span class="ml-auto">
                        <font-awesome-icon :icon="['fa', 'plus-circle']" />
                      </span>
                  </button>
                </a>
            </div>
          </div>
        </div>
        <div class="button-group">
          <div class="button-group-title">Respondents</div>
          <div class="button-row respondents-button-row">          
            <a
              href="/api/v1/survey/download-survey-respondent-template"
              @click.prevent="downloadItem()"
              id="DownloadSurveyRespondentTemplate"
              :class="[this.table.rowSelected ? '' : 'disabled disable-link']"
              role="button"
              aria-disabled="true"
            >
              <button type="button" class="btn btn-primary mr-2" :disabled="!table.rowSelected">
                <span class="align-text-bottom">{{ downloadTemplateButton }} <font-awesome-icon :icon="['fa', 'download']"/></span>
                <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
              </button>
            </a>
            <a class="pr-2" href="#" @click="openImportModal">
              <button type="button" class="btn btn-primary">
                <span class="align-text-bottom">{{ importDataButton }} <font-awesome-icon :icon="['fas', 'upload']"/></span>
              </button>
            </a>
            <a class="pr-2" 
              href="#" 
              :class="[this.table.rowSelected ? '' : 'disabled disable-link']"
              role="button"
              aria-disabled="true"
              @click="gradeResponses">
              <button type="button" class="btn btn-primary" :disabled="!table.rowSelected">
                <span class="align-text-bottom">{{ gradeResponsesButton }} </span>
              </button>
            </a>
            <a class="pr-2" href="#" @click="openManageResponses('Grade Responses','grade')">
              <button type="button" class="btn btn-primary">
                <span class="align-text-bottom">Grade List</span>
              </button>
            </a>          
            <a class="pr-2" href="#" @click="openManageResponses('Delete Responses','delete')">
              <button type="button" class="btn btn-primary">
                <span class="align-text-bottom">Delete List</span>
              </button>
            </a>          
          </div>
        </div>
        <div class="button-group last-group">
          <div class="button-group-title">Configuration</div>
          <div class="button-row configure-button-row">          
            <a class="pr-2" 
              href="#" 
              :class="[this.table.rowSelected ? ' ' : 'disabled disable-link']"
              role="button"
              aria-disabled="true"
              @click="openSetupGradeThresholds">
              <button type="button" class="btn btn-primary" :disabled="!table.rowSelected">
                <span class="align-text-bottom">{{ setupGradeThresholdsButton }}</span>
              </button>
            </a>
            <div class="pr-2">
              <button type="submit" class="btn btn-primary" v-on:click="showManageExclusionListModal">Manage Exclusive List</button>
            </div>
          </div>
        </div>
      </div>
      <div class="data-container mt-4">
        <div class="col-12">
          <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
            <thead>
              <tr>
                <th style="width: 0rem"><p class="m-0 p-0" style="width: 0rem">Survey Code</p></th>
                <th style="width: 5rem"><p class="m-0 p-0" style="width: 5rem">Survey Name</p></th>
                <th style="width: 5rem"><p class="m-0 p-0" style="width: 6rem">Survey Code</p></th>
                <th style="width: 5rem"><p class="m-0 p-0" style="width: 3rem">Survey Id</p></th>
                <th style="width: 5rem"><p class="m-0 p-0" style="width: 5rem">Survey Title</p></th>
                <th style="width: 5rem"><p class="m-0 p-0" style="width: 5rem">Survey Url</p></th>
                <th style="width: 5rem"><p class="m-0 p-0" style="width: 5rem">Client</p></th>
                <th style="width: 5rem"><p class="m-0 p-0" style="width: 5rem">Parent Client</p></th>
                <th style="width: 5rem"><p class="m-0 p-0" style="width: 3rem">Build Required</p></th>
                <th style="width: 5rem"><p class="m-0 p-0" style="width: 5rem">Status</p></th>
                <th style="width: 5rem"><p class="m-0 p-0" style="width: 3rem">Date Created</p></th>
                <th style="width: 5rem"><p class="m-0 p-0" style="width: 35rem">Date Updated</p></th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
    <vue-modal ref="copy-survey" name="copy-survey" :width="368" :height="250">
      <div class="container">
        <div class="form-group form-row p-2">
          <h5 class="font-weight-bold">Copy Survey</h5>
        </div>
        <div class="form-group form-row p-2 text-success">
          {{ modalReturnMessage }}
        </div>
        <div class="form-group form-row p-2">
          Please select the number of copies you want to make
          <select id="numberOfCopies" name="numberOfCopies" class="custom-select required" v-model="numberOfCopies">
            <option v-for="(copy_option, idxcopyOptions) in copyOptions" v-bind:value="copy_option" v-bind:key="idxcopyOptions">{{ copy_option }}</option>
          </select>
        </div>
        <div class="form-group form-row d-flex justify-content-end">
          <button type="submit" class="btn btn-primary ml-2" v-on:click="this.copySurvey">
            <span>Copy Survey</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-4"
            @click="
              this.$refs['copy-survey'].visible = false;
              $router.go(0);
            "
          >
            Close
          </button>
        </div>
      </div>
    </vue-modal>
    <vue-modal ref="import-respondent-data-file" name="import-respondent-data-file" :width="368" :height="400">
      <div class="container">
        <div class="form-group form-row p-2">
          <h5 class="font-weight-bold">{{ importDataButton }}</h5>
        </div>
        <div class="form-group form-row p-2">
          {{ returnMessage }}
        </div>
        <div class="form-group form-row">
          <span class="pr-2">Select a file to upload</span>
          <input ref="importRespondentDataUpload" type="file" @change="onFileChange" />
        </div>
        <div class="form-group form-row d-flex justify-content-end">
          <button type="submit" class="btn btn-primary ml-2" v-on:click="this.importFile">
            <span>Import File</span>
            <span v-show="getModalLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-4"
            @click="
              this.$refs['import-respondent-data-file'].visible = false;
              $router.go(0);
            "
          >
            Close
          </button>
        </div>
      </div>
    </vue-modal>
    <survey-locale-config 
      :input-survey="getSurveyObject()"
      modalTitle="Add Survey Locale"
      :modal-width="790"
      mode="add-from-survey-list" />

    <manage-exclusion-list ref="manageExclusionList" type="Master"/>
    <setup-grade-thresholds :input-survey-code="getSurveyCode()"/>
    <manage-responses :modal-title="manageResponsesTitle" :input-mode="manageResponsesMode"/>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import Vue from "vue";
import { isMobile } from "mobile-device-detect";
import SetupGradeThresholds from "../../modals/SetupGradeThresholds.vue";
import ManageExclusionList from "../../modals/ManageExclusionList.vue";
import SurveyLocaleConfig from "../../modals/SurveyLocaleConfig.vue";

import AdminMixins from "../../mixins/AdminMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import authHeader from "../../services/auth-header";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";
import UserService from "../../services/user.service";
import SurveyService from "../../services/survey.service";
import ClientService from "../../services/client.service";
import SurveySummaryService from "../../services/survey.summary.service";
import ManageResponses from "../../modals/ManageResponses.vue";

export default {
  name: "SurveyList",
  mixins: [AdminMixins, DataTableMixins],
  components: {SetupGradeThresholds,ManageExclusionList,SurveyLocaleConfig,ManageResponses},
  data() {
    return {
      title: "Survey List",
      table: {
        id: "survey-list-table",
        columns: [],
        configuration: {
          dom: '<"top"if>rtp<"bottom">B<"clear">',
          searching: true,
          paging: true,
          select: true,
          destroy: true,
          info: false,
          ordering: true,
          bSort: true,
          bSortable: true,
          keys: true,
          autoWidth: false,
          processing: true,
          responsive: true,
        },      
      },
      returnMessage: "",
      copyOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      numberOfCopies: 1,
      importRespondentDataImportFile: "",
      currentSurveyName: "",
      manageResponsesTitle: "Grade Responses",
      manageResponsesMode: "grade",
    };
  },
  methods: {
    openManageResponses(modalTitle, mode){
      this.manageResponsesTitle = modalTitle;
      this.manageResponsesMode = mode;
      this.$modal.show("manage-responses");
    },    
    showManageExclusionListModal(){
      this.$refs.manageExclusionList.loadData();
      this.$modal.show("manage-exclusion-list");
    },
    onFileChange: function() {
      this.importRespondentDataImportFile = this.$refs.importRespondentDataUpload.files[0];
    },
    openImportModal: function() {
      this.returnMessage = "";
      this.$modal.show("import-respondent-data-file");
    },
    openSetupGradeThresholds: function() {
      this.returnMessage = "";
      this.$modal.show("setup-grade-thresholds");
    },
    gradeResponses: function(){
      this.$root.processServerRequest("Grading responses");
      SurveySummaryService.gradeResponsesBySurveyId(this.table.rowSelectedId, this.gradeResponsesCallback, this);
    },
    gradeResponsesCallback: function(response){
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async importFile() {
      this.$root.processServerRequest("Importing respondent data");
      this.setModalLoading(true);
      let formData = new FormData();
      formData.append("file", this.importRespondentDataImportFile);
      formData.append("user_id", UserService.getUserId());
      let headers = { "Content-Type": "multipart/form-data" };
      $.extend(headers, await authHeader());

      await axios
        .post(this.baseUrl + "/api/v1/admin/import-respondent-data", formData, { headers: headers })
        .then(response => this.importFileCallback(response))
        .catch(error => {
          // eslint-disable-next-line no-console
          if (error.response.status == 504) {
            let timeoutError = "This process takes more than 5 minutes. Please check your email. We will send a response once the process completes";
            AdminService.displayInfoMessage({ text: timeoutError, duration: -1 });
          }  else {
            error.message += ErrorService.mergeError(error.response);
            ErrorService.processError("SurveyList.importFile", error, "admin", this);
          } 
        });
    },
    importFileCallback: function(response) {
      this.$root.processServerResponse();
      this.setModalLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.returnMessage = response.data.message;
        this.$refs['import-respondent-data-file'].visible = false;
        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    copySurvey() {
      this.$root.processServerRequest();
      this.modalReturnMessage = "Making copies of the survey please stand by....";
      SurveyService.copySurvey(this.table.rowSelectedId, this.numberOfCopies, this.copySurveyCallback, this);
    },
    copySurveyCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.table.dataTable.draw(true);
        this.modalReturnMessage = response.data.message;
        if (!jQuery.isEmptyObject(response.data.data) && Array.isArray(response.data.data)) {
          this.clientObject.surveyCodes = response.data.data;
          ClientService.saveClient(this.clientObject);
          this.$root.setClientObject(this.clientObject);
        }
        AdminService.displaySuccessMessage({ text: response.data.message });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    deleteSurvey() {
      this.$root.processServerRequest("Deleting survey");
      SurveyService.deleteSurvey(this.table.rowSelectedId, this.deleteSurveyCallback, this);
    },
    deleteSurveyCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.table.dataTable.draw(true);
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.getTableData();
        if (!jQuery.isEmptyObject(response.data.data)) {
          this.clientObject.surveyCodes = response.data.data;
          ClientService.saveClient(this.clientObject);
          this.$root.setClientObject(this.clientObject);
        }
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async getTableData() {
      this.$root.processServerRequest("Loading table data");
      await axios
        .get(this.baseUrl + "/api/v1/survey/get-survey-list", { headers: await authHeader() })
        .then(response => this.getTableDataCallback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("SurveyList.getTableData", error, "survey-designer", this);
        });
    },
    getDownloadFilename() {
      return "Survey Responses Template - " + this.currentSurveyName;
    },
    async downloadItem() {
      this.setupDownloadItem();
      this.currentSurveyName = this.table.data.find(c => c.surveyId == this.table.rowSelectedId).surveyName;
      await axios({
        url: this.baseUrl + "/api/v1/survey/download-survey-respondent-template?id=" + this.table.rowSelectedId + "&userId=" + UserService.getUserId(),
        method: "GET",
        responseType: "blob"
      })
      .then(response => this.downloadItemCallback(response))
      .catch(error => this.downloadItemError(error));
    },
    getSurveyObject() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined") {
        return selectedRowObject;
      }
      return {surveyName: ""};
    },
    getSurveyCode() {
      let selectedRowObject = this.getSurveyObject();
      if (typeof selectedRowObject != "undefined"){
        return selectedRowObject.surveyCode;
      }
      return "";
    },
  },
  computed: {},
  created() {
    if (!isMobile) {
      this.setupGradeThresholdsButton = "Setup Grade Thresholds";
      this.gradeResponsesButton = "Grade Survey";
      this.importDataButton = "Import Data";
      this.downloadTemplateButton = "Download Template";
    }
    this.table.columns = SurveyService.getTableColumnData();
    if (this.isAdmin) {
      this.getTableData();
    }
  },
  props: {},
  mounted() {}
};
</script>
