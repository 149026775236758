<template>
  <vue-modal :key="refreshModal" :ref="modalName" :name="modalName" :scrollable="true" :width="500" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Insights {{ reportPageTypeName }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="insights">
          <div class="text-center m-1 info-message">{{ returnMessage }}</div>
          <div v-show="newClientChartNeeded" class="form-group form-row align-items-center">
            <label for="name" class="m-1 font-weight-bold">Chart Name</label>
            <input v-model="savedChartName" id="name" name="name" type="text" class="form-control col-9 w-100" />
          </div>
          <div class="description">
            <div class="header">Description</div>
            <div class="body">
              <div class="mt-2" v-html="description"></div>
            </div>
          </div>
          <div class="comments">
            <div class="header">Insights</div>
            <div class="body">
              <textarea id="insightContentText" name="insightContentText" class="form-control w-100"> </textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4 btn-insight" @click="saveInsights">Save Insights</button>
        <button type="button" class="btn btn-primary ml-2" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import $ from "jquery";
import ContentMixins from "../mixins/ContentMixins.vue";

import ReportService from "../services/report.service";
import SurveyService from "../services/survey.service";
import AdminService from "../services/admin.service";
import GoogleService from "../services/google.service";

export default {
  name: "Insights",
  components: {},
  mixins: [ContentMixins],

  methods: {
    initializeInsights: function(clientChart) {
      this.description = ReportService.getInsightDescription(this.reportPageTypeName, this.entryPageContent);
      if (typeof this.reportPageInput.overview != "undefined") {
        this.insightContentText = this.reportPageInput.overview;
      } else {
        this.insightContentText = "";
      }
      if (this.reportPageInput.reportPageId == 0) {
        this.reportPageInput.reportPageTypeName = this.reportPageTypeName;
      }
      this.clientChart = JSON.parse(JSON.stringify(clientChart));
      if (this.clientChart.clientChartId == 0) {
        this.savedChartName = this.clientChart.name;
      }
      let vm = this;
      $(document).ready(function() {
        $("#insightContentText").summernote(vm.summernoteConfiguration);
        $("#insightContentText").summernote("code", vm.insightContentText);
      });
    },
    saveInsights: function() {
      GoogleService.logBaseEvent("save insights", SurveyService.getSurveyName(), this.reportPageTypeName, 0, this);

      this.$root.processServerRequest();
      this.insightContentText = $("#insightContentText").summernote("code");
      this.reportPageInput.overview = this.insightContentText;
      if (this.clientChart.clientChartId == 0) {
        this.clientChart.name = this.savedChartName;
        this.clientChart.surveyId = SurveyService.getSurveyId();
      }
      ReportService.saveOverview(this.reportPageInput, this.clientChart, this.saveOverviewCallback, this);
    },
    saveOverviewCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.returnMessage = response.data.message;
        let returnClientChart = response.data.data.clientChart;
        let returnReportPage = response.data.data.reportPage;
        if (typeof returnClientChart != "undefined") {
          this.clientChart = JSON.parse(JSON.stringify(returnClientChart));
          this.$emit("saveClientChart", returnClientChart, response.data.message);
        }
        if (typeof returnReportPage != "undefined") {
          this.reportPageInput = JSON.parse(JSON.stringify(returnReportPage));
          this.$emit("saveReportPage", this.reportPageInput);
          this.$emit("saveAllReportPages");
        }
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    closeModal() {
      this.$refs[this.modalName].visible = false;
      this.refreshModal = !this.refreshModal;
    },
  },
  props: {
    modalName: {
      type: String,
      default: "insights"
    },
    reportPageTypeName: {
      type: String,
      default: ""
    },
    entryPageContent: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      summernoteConfiguration: ReportService.getReportConfiguration(),
      savedChartName: "",
      insightContentText: "",
      reportPageInput: "",
      description: "",
      returnMessage: "",
      clientChart: {
        clientChartId: 0
      },
      refreshModal: true
    };
  },
  computed: {
    newClientChartNeeded: function() {
      return this.clientChart.clientChartId == 0;
    }
  },
  created() {},
  mounted() {},
  watch: {}
};
</script>
