<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" :scrollable="true" :adaptive="true"  @opened="loadData" :resizable="true" :width="modalWidth" :minHeight="700" :maxHeight="1550" :height="'auto'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
            <div :class="isError ? 'form-group form-row text-danger' : 'form-group form-row text-success'">
                {{ modalReturnMessage }}
            </div>
            <div style="min-height: 350px;">
              <div class="d-flex justify-content-end">
                  <locale-import-buttons 
                  v-if="addFromSurveyList"
                    :table="table"
                    is-modal-loading:="getModalLoading"
                    :current-survey-locale="inputSurvey"
                    @set-modal-loading:="setModalLoading"
                    @trigger-open-import-modal="openImportModal"
                    @trigger-locale-preview-modal="openLocalePreviewModal"
                  />
                </div>

                <div v-if="addFromSurveyList" class="form-row form-group form-inline">
                  <label for="surveyId" class="col-2 mr-1">Survey</label>
                  <span class="w-75">{{ inputSurvey.surveyName }}</span>
                </div>
                <div class="form-row form-group form-inline">
                    <label for="localeId" class="col-2 mr-1">*Locale</label>
                    <select id="localeId" name="localeId" class="w-75 custom-select required" v-model="surveyLocale.localeId">
                        <option value="0">Select Locale</option>
                        <option v-for="(locale_option, idxLocaleOptions) in this.localeOptions" v-bind:value="locale_option.localeId" v-bind:key="idxLocaleOptions">
                            {{ locale_option.countryName }} - {{ locale_option.languageName }}
                        </option>
                    </select>
                </div>
                <div v-if="!addFromSurveyList" class="form-row form-group form-inline">
                    <label for="surveyId" class="col-2 mr-1">*Survey</label>
                    <v-select
                      class="w-75"
                      id="surveyCode"
                      name="surveyCode"
                      :options="surveyOptions"
                      :reduce="survey => survey.surveyId"
                      label="name"
                      :clearable="false"
                      v-model="surveyLocale.surveyId"
                    >
                  </v-select>                    
                </div>
                <div class="form-row form-group form-inline">
                  <label for="isDefault" class="control-label col-2 mr-1">Is Default</label>
                  <input type="checkbox" id="isDefault" name="isDefault" v-model="surveyLocale.isDefault" true-value="Y" false-value="N"/>
                </div>
                <div v-if="addFromSurveyList" class="pt-2">
                  <h4 class="text-primary w-100 text-center">Existing Locales</h4>
                  <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
                    <thead></thead>
                    <tbody></tbody>
                  </table>
                </div>
            </div>                
        </div>
        <preview-locale 
        :input-survey-locale="getSurveyLocaleObject()"
        />
        <import-locale-file 
          :input-survey-locale-id="table.rowSelectedId"
            @reload="reloadTable"/>        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary ml-4" @click="saveLocale" :disabled="disableSave">Save</button>
        <button type="button" class="btn btn-primary ml-4" @click="closeModal">Close</button>
      </div>
    </div>
  </vue-modal>
</template>

<script>
import AdminService from "../services/admin.service";
import AlertService from "../services/alert.service";
import LocaleService from "../services/locale.service";
import ViewMixins from "../mixins/ViewMixins.vue";
import DataTableMixins from "../mixins/DataTableMixins.vue";
import LocaleImportButtons from "../components/LocaleImportButtons.vue";
import ImportLocaleFile from "../modals/ImportLocaleFile.vue";
import PreviewLocale from "../modals/PreviewLocale.vue";

export default {
  name: "SurveyLocaleConfig",
  mixins: [ViewMixins,DataTableMixins],
  components: {LocaleImportButtons,ImportLocaleFile,PreviewLocale},
  data() {
    return {
      modalReturnMessage: "",
      localeOptions: [],
      surveyOptions: [],
      isError: false,
      disableSave: false,
      surveyLocale: LocaleService.getSurveyDefaultLocale(),
      table: {
        id: "survey-locale-list-table",
        columns: [],
        configuration: {
          searching: false,
          responsive: false,
          dom: '<"top">rtp<"bottom"><"clear">',
        }
      },
      refreshModal: true
    };
  },
  computed: {
    addFromSurveyList: function(){
      return this.mode == "add-from-survey-list";
    },
  },
  mounted() {},
  created() {},
  methods: {
    saveLocale() {
      this.modalReturnMessage = "";
      AlertService.closeAlert();
      this.isError = false;
      if (this.surveyLocale.surveyId == 0) {
          this.modalReturnMessage += "Survey field is required. ";
          this.isError = true;
      } 
      if (this.surveyLocale.localeId == 0) {
          this.modalReturnMessage += "Locale field is required";
          this.isError = true;
      } 
      if (this.isError == false){
        this.$root.processServerRequest("Saving survey locale");
        LocaleService.saveSurveyLocale(this.surveyLocale, this.saveSurveyLocaleCallback, this);
      } else {
        AdminService.displayErrorMessage({ text: this.modalReturnMessage });
      }      
    },
    saveSurveyLocaleCallback(response) {
      this.isError = false;
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.modalReturnMessage = response.data.message;
        AdminService.displaySuccessMessage({ text: response.data.message });
        this.surveyLocale =  LocaleService.getSurveyDefaultLocale();
        if (this.addFromSurveyList){
          this.getTableData();
        } else {
          this.$emit("reload"); 
        }
      } else {
        this.isError = true;
        this.modalReturnMessage = response.data.message;
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async loadData(){
        if (this.addFromSurveyList){
          this.getTableData();
          this.surveyLocale.localeId = 0;
        } else {
          this.localeOptions = JSON.parse(JSON.stringify(this.inputLocaleOptions));
          this.surveyOptions = JSON.parse(JSON.stringify(this.inputSurveyOptions));
          if (this.mode == "add"){
            this.surveyLocale = LocaleService.getSurveyDefaultLocale();
          } else {
            this.surveyLocale = JSON.parse(JSON.stringify(this.inputSurveyLocale));
          }
        }
        this.modalReturnMessage = "";
    },
    async getTableData() {
      this.table.columns = LocaleService.getSurveyLocaleColumnsMini();
      this.$root.processServerRequest("Loading table data");
      this.surveyLocale.surveyId = this.inputSurvey.surveyId;
      await LocaleService.getSurveyLocaleOptions(this.getSurveyLocaleOptionsCallback, this);
      await LocaleService.getSurveyLocaleListBySurveyId(this.inputSurvey.surveyId, this.getTableDataCallback, this);
    },      
    getSurveyLocaleOptionsCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.surveyOptions = response.data.data.surveyOptions;
        this.localeOptions = response.data.data.localeOptions;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    getSurveyLocaleObject() {
      let selectedRowObject = this.getDataFromSelectedRow();
      if (typeof selectedRowObject != "undefined") {
        return selectedRowObject;
      }
      return LocaleService.getSurveyDefaultLocale();
    },    
    getLocaleCallback: function (response) {
      this.isError = false;
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.modalReturnMessage = response.data.message;
        this.setDisplayView(true);
        this.user_options= response.data.data;
        AdminService.displaySuccessMessage({ text: response.data.message, duration: 1000 });
      } else {
        this.isError = true;
        this.modalReturnMessage = response.data.message;
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },  
    openImportModal: function() {
      this.modalReturnMessage = "";
      this.$modal.show("import-locale-file");
    },    
    openLocalePreviewModal: function() {
      this.modalReturnMessage = "";
      this.$modal.show("preview-locale");
    },    
    reloadTable() {
      this.getTableData();
    },    
    closeModal(){
      this.modalReturnMessage = "";
      this.$refs[this.modalId].visible = false;
      this.refreshModal = !this.refreshModal;
    },    
  },
  watch: {
    inputSurveyLocale: function () {
      this.surveyLocale = JSON.parse(JSON.stringify(this.inputSurveyLocale));
    },
  },
  props: {
    inputSurveyLocale: {
      type: Object,
      default: function () {
        return {};
      },
    },    
    inputSurvey: {
      type: Object,
      default: function () {
        return {};
      },
    },    
    inputSurveyOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },    
    inputLocaleOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },    
    mode: {
      type: String,
      default: "add",
    },
    modalId: {
      type: String,
      default: "survey-locale-config",
    },
    modalTitle: {
      type: String,
      default: "Configure Locale",
    },
    modalWidth: {
      type: Number,
      default: 368,
    },
  }
};
</script>
