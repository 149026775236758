<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <modal-link modal-id="How to Use"></modal-link>Trends
      <smart-center-link page="Trends" location="Header" />
      <div class="ml-auto">
        <span class="wizard-icon">
          <!--
          <font-awesome-icon v-tooltip="tooltipConfigShow" :icon="['fas', 'hat-wizard']" @click="$root.toggleWizardContent()" />
          -->
          <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
        </span>
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()"
          class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()"
          class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <chart-menu></chart-menu>
    <div v-show="$root.getShowWizardContent()" class="wizard-content">
      <div class="wizard-content-column-two-entries wizard-border col-3">
        <div class="w-100 d-flex">
          <div class="circle">1</div>
          <div class="wizard-text">
            Click Metric to select the set of Growth
            data to trend against your company’s
            performance. Note: To add Growth data,
            contact your wRatings Advisor.
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">2</div>
          <div class="wizard-text">
            Click Performance to select which set of
            data to trend. Click Start Period to specify
            which Wave to begin showing trends.
            Note: To modify Waves, contact your
            wRatings Advisor.
          </div>
        </div>
      </div>
      <div class="wizard-content-column-two-entries wizard-border col-3">
        <div class="w-100 d-flex">
          <div class="circle">3</div>
          <div class="wizard-text">
            Click Groups to override the current survey
            selected for Trends. To run Trends for the
            current survey, select None. Note: To
            modify Groups, contact your wRatings
            Advisor.
          </div>
        </div>
        <div class="w-100 d-flex  pt-2">
          <div class="circle">4</div>
          <div class="wizard-text">
            Click the box to include partial responses,
            which are respondents that did not
            complete all the survey fields.
          </div>
        </div>
      </div>
      <div class="wizard-content-column wizard-border col-3">
        <div class="w-100 d-flex">
          <div class="circle">5</div>
          <div class="wizard-text">
            Click up to 5 competitors
            to include in your Profiles.
            Click the Benchmarks to compare
            your data to any of our
            well-recognized
            companies.
          </div>
        </div>
      </div>
      <div class="wizard-content-column-two-entries col-3">
        <div class="w-100 d-flex">
          <div class="circle">6</div>
          <div class="wizard-text">
            Segments are created through your survey
            questions. Click any segment criteria to
            select which respondents to include in your
            Profile. Selecting no criteria includes all
            respondents for that question. Segments
            for NPS, Location and Word are included as
            defaults.
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">7</div>
          <div class="wizard-text">
            Click Generate Chart to build your Profile.
            Click Reset to start the build process from
            scratch.
          </div>
        </div>
      </div>
    </div>
    <div class="chart-app">
      <chart-criteria ref="chartCriteria" :show-custom-time-period="false" :show-metric="true" :show-display-type="true" @reset-client-chart="resetClientChart"
        display-type-label="Performance" display-type-modal-name="Performance" time-period-label="Start Period" :is-comparative="false"></chart-criteria>
      <div class="row no-gutters">
        <div class="col-12">
          <div id="chartDislayCard">
            <div id="performance_dashboard">
              <chart-display-skeleton v-show="generatingChart"/>
              <div id="chart-filters" class="row">
                <chart-criteria-results ref="chartCriteriaResults" v-bind:criteria-results-list="criteriaResultsList"
                  v-show="!generatingChart"
                  v-bind:criteria-results-company-list="criteriaResultsCompanyList"></chart-criteria-results>
              </div>
              <div v-show="!isMoatTrend && getDisplayView() && !generatingChart" class="row mt-3 mb-3">
                <div class="col-lg-12">
                  <button id="save_chart" class="btn btn-primary ml-2" type="button"
                    @click="$modal.show('save-chart-confirmation')" :disabled="disableSaveAsCardButton">Save as
                    Card</button>
                </div>
              </div>
              <MoatTrend v-show="isMoatTrend  && !generatingChart" :chart-data="chartData"></MoatTrend>
              <div v-show="!isMoatTrend" id="chart-container" class="by-value">
                <div id="competitive-trends-chart" class="chart-row" v-show="getDisplayView()">
                  <profile-chart-header
                      :title="chart.title"
                      :input-display-number-of-responses="eChartCriteria.displayNumberOfResponses"
                      @set-display-number-of-responses="setDisplayNumberOfResponses"
                  />
                  <trends-chart ref="eChart"
                      v-show="!showNpsTrends()"
                      :e-chart-mini-id="'trends-chart-mini'"
                      :e-chart-id="'trends-chart'"
                      :input-report-type-name="'Chart - Competitive Trends'"
                      :modal-id="'competitive-trends-chart'"
                      :is-saved-card="this.clientChart.clientChartId != 0" 
                      :input-client-chart="this.clientChart"
                      @save-table="saveProfileDataTable"
                      @save-criteria="buildClientChart"/>

                  <NPS-chart ref="npsChart"
                      v-show="showNpsTrends()"
                      :e-chart-mini-id="'nps-chart-mini'"
                      :e-chart-id="'nps-chart'"
                      :is-match="true"
                      :display-title="false"
                      :input-report-type-name="'Chart - Competitive Trends'"
                      :modal-id="'competitive-trends-chart'"
                      :input-client-chart="this.clientChart"
                      :is-saved-card="this.clientChart.clientChartId != 0" 
                      @save-table="saveProfileDataTable"
                      @save-criteria="buildClientChart"/>                                          
                </div>
                <div v-show="!showNpsTrends() && getDisplayView()" :id="tableContainerId"
                  :class="isInsiderUser ? 'chart-data hide-table-data' : 'chart-data'">
                  <div v-show="isInsiderUser" class="overlay">
                    <img class="overlay-inner" v-show="isInsiderUser" src="../../assets/img/overlap.png"  style="width: 50%"/>
                  </div>
                  <chart-data-table 
                    ref="competitiveTrendsDataTable"
                    :chart-data="table.data" :container-id="'competitive-trends'"
                    :is-saved-card="this.clientChart.clientChartId != 0" 
                    :data-container-id="tableContainerId" :column-defs="this.chart.columnDefs"
                    :table-class="'table display row-border table-hover'" :table-width="'auto'"
                    :table-title="tableTitle" :report-page-type-name="'Table - Competitive Trends'"
                    :insight-modal-id="'competitive-trends-table'"></chart-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title"
          :modal-content="pageContent.contentText" />
      </div>
      <vue-modal ref="save-chart-confirmation" name="save-chart-confirmation" :width="350" :height="200">
        <save-chart :chart-title="this.chart.title" :initial-client-chart="this.clientChart"
          @save="updateClientCharts" @closeModal="closeModal('save-chart-confirmation')"></save-chart>
      </vue-modal>
      <insights ref="insightsChart" :modal-name="'competitive-trends-chart'"
        :report-page-type-name="'Chart - Competitive Trends'" :entry-page-content="this.pageContentForReport"
        @saveReportPage="updateReportPages" @saveAllReportPages="saveAllReportPages"
        @saveClientChart="saveClientChart" />
      <insights ref="insightsTable" :modal-name="'competitive-trends-table'"
        :report-page-type-name="'Table - Competitive Trends'" :entry-page-content="this.pageContentForReport"
        @saveReportPage="updateReportPages" @saveAllReportPages="saveAllReportPages"
        @saveClientChart="saveClientChart" />
    </div>
  </div>
</template>
<script>
import $ from "jquery";

import ChartDisplaySkeleton from "../../components/skeleton/ChartDisplaySkeleton.vue";
import ChartCompanyMixins from "../../mixins/ChartCompanyMixins.vue";
import MoatTrend from "./type/MoatTrend.vue";

import ProfileChartHeader from "../../components/echarts/ProfileChartHeader.vue";
import TrendsChart from "../../views/echarts/TrendsChart.vue";
import NPSChart from "../../views/echarts/NPSChart.vue";

import SaveChart from "../../components/chart/SaveChart.vue";
import ChartService from "../../services/chart.service";
import ErrorService from "../../services/error.service";
import AdminService from "../../services/admin.service";
import TableService from "../../services/table.service";
import ReportService from "../../services/report.service";
import TrendsService from "../../services/echarts/trends.service";

export default {
  name: "CompetitiveTrends",
  mixins: [ChartCompanyMixins],
  components: {
    SaveChart,
    MoatTrend,
    TrendsChart,
    NPSChart,
    ChartDisplaySkeleton,
    ProfileChartHeader
  },
  data() {
    return {
      title: "Trends",
      displayType: "trends",
      tableContainerId: "competitive-trends-data-container",
      pageNumber: 3,
      npsChartDataByTimePeriod: -1,
      chartData: {},
      redrawAdditionalWidgets: true,
      competitiveTrendsOptions: {},
      emotionalNeedSelected: false,
      insightModalId: "",
      
      isMoatTrend: false
    };
  },
  methods: {
    closeModal(name) {
      if(this.$refs[name] != null) {
        this.$refs[name].visible = false;
      }
    },
    displayInsights(insightModalId, reportPageTypeName) {
      if (reportPageTypeName == "Chart - Competitive Trends") {
        this.$refs.insightsChart.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.reportPagesList);
        this.$refs.insightsChart.initializeInsights(this.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Table - Competitive Trends") {
        this.$refs.insightsTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.reportPagesList);
        this.$refs.insightsTable.initializeInsights(this.clientChart);
        this.$modal.show(insightModalId);
      }
    },
    async displayChartCallback(response) {
      try {
        this.$root.processServerResponse();
        this.generatingChart = false;

        let returnData = response.data;
        this.chartData = returnData[0];
        let chartDataToDisplay = JSON.parse(JSON.stringify(this.chartData));
        let otherData = chartDataToDisplay["otherData"];
        if (typeof otherData["isMoatTrends"] != "undefined") {
          this.isMoatTrend = otherData["isMoatTrends"];
        } else {
          this.isMoatTrend = false;
        }
        if (this.isMoatTrend) {
          this.displayType = "moat-trends";
          this.chartData = response.data;
          return;
        } else {
          this.displayType = "trends";
        }


        this.emotionalNeedSelected = otherData["emotionalNeedSelected"];
        let companyColorList = otherData["companyColorList"];
        TableService.setCompanyColorList(companyColorList);
        let breakPointList = otherData["breakPointList"];
        ChartService.setVerticalLineBreakPoints(breakPointList);

        let dynamicChartOptions = chartDataToDisplay["options"];
        this.chart.title = typeof dynamicChartOptions.title != "undefined" ? dynamicChartOptions.title : "Summary";
        let containerId = chartDataToDisplay["containerId"];

        if (!this.showNpsTrends()) {
          this.setChartResults(chartDataToDisplay.companyList);
          this.criteriaResultsList = AdminService.removeByName(chartDataToDisplay.criteriaList, "Competitor");
          this.table.id = containerId;
          this.table.data = chartDataToDisplay;
          chartDataToDisplay = this.drawTimePeriodName(chartDataToDisplay);
        }
        this.$root.$emit("check-number-of-responses");
        this.setDisplayView(true);
        this.$root.processServerResponse();
        this.setEChartCriteria(this.$refs.chartCriteria.eChartCriteria,false);
        this.buildClientChart();

        if (this.showNpsTrends()) {
          let npsChartDataByTimePeriod = otherData["npsChartDataByTimePeriod"];
          this.npsChartDataByTimePeriod = npsChartDataByTimePeriod;
          this.drawNPSChart();
        } else {
          this.chart.columnDefs[2] = TableService.getPercentTableAttribute([1], "dt-right chart-light-green", 75, 2);
          let vAxisForMetric = otherData["vAxisForMetric"];
          if (typeof vAxisForMetric !== 'undefined' && vAxisForMetric !== null ){
            let formatter = vAxisForMetric.formatter;
            let isCurrency = formatter != null && formatter.includes("$");          
            if (isCurrency){
              this.chart.columnDefs[2] = TableService.getMoneyTableAttribute([1], "dt-right chart-light-green", 75, 2);
            }
          }
          this.parseForTrends(this.chartData);
          this.$refs.eChart.processChart(true,this.displayType,this.eChartData,this.eChartCriteria,this.emotionalNeedSelected);
        }
        $("#competitive-trends-chart").addClass("chart-wrapper");
      } catch (err) {
        ErrorService.processException("CompetitiveTrends.displayChartCallback", err, "chart", this);
      }
    },
    drawNPSChart: function() {
      this.$refs.npsChart.processChart(true,this.npsChartDataByTimePeriod,this.eChartCriteria);
    },
    buildClientChart: function(){
      this.rebuildClientChart(this.clientId );
    },
    rebuildClientChart(client_id) {
      let useSaveParameters = typeof this.clientChart.criteria != undefined && this.clientChart.criteria != "" && this.autoGenerateChartRun;
      const params = useSaveParameters ? new URLSearchParams(this.clientChart.criteria) : this.$refs.chartCriteria.buildUrlParams(client_id);
      this.clientChart.criteria = params.toString();
    },    
    parseForTrends:function(inputData){
      let itemsToRemove = {
          lastColumns: 0,
          firstColumns: 0
        };
      let data = {};
      let chart1Data = inputData;
      data = TrendsService.parseData(1,chart1Data,data,itemsToRemove,false);
      data = TrendsService.parseLegendAndColorData(chart1Data,data);
      data = TrendsService.parseAxisData(chart1Data,data);
      this.eChartData = JSON.parse(JSON.stringify(data));
    },    
    saveAllReportPages() {
      this.saveProfileDataTable("competitive-trends");
    },    
    async saveProfileDataTable(containerId) {
      if (this.clientChart.clientChartId != 0  && this.isGenerateReportRunning()) {
          await ReportService.saveReportTableFile(
            this.$refs.competitiveTrendsDataTable.getTableData(),
            "Table - Competitive Trends", 
            this.clientChart, 
            this.saveReportTableFileCallback, 
            this
          );

          await ReportService.saveReportPage(
            this.getReportTableContent(containerId), 
            "Table - Competitive Trends", 
            this.clientChart, 
            this.saveReportPageCallback, 
            this
          );      
      }
    },    
    drawTimePeriodName(inputChartData) {
      for (let indexRows = 0; indexRows < inputChartData.rows.length; indexRows++) {
        let chartDataRow = inputChartData.rows[indexRows].c;
        let value = chartDataRow[0].v;
        if (typeof value != "undefined") {
          let position = value.indexOf("(");
          let firstPart = value.substring(0, position);
          chartDataRow[0].v = firstPart;
        }
      }
      return inputChartData;
    },
    showNpsTrends: function () {
      if (typeof this.$refs.chartCriteria != "undefined" &&
        typeof this.$refs.chartCriteria.showNpsTrends != "undefined") {
        return this.$refs.chartCriteria.showNpsTrends;
      }
      return false;
    },
    setDisplayNumberOfResponses(inputDisplayNumberOfResponses){
      this.eChartCriteria.displayNumberOfResponses = inputDisplayNumberOfResponses;
      this.$refs.chartCriteria.eChartCriteria.displayNumberOfResponses = inputDisplayNumberOfResponses;
      if (this.showNpsTrends()) {
          this.drawNPSChart();      
      } else {
        this.$refs.eChart.processChart(this.getDisplayView(),this.displayType,this.eChartData,this.eChartCriteria,this.displayPricingPower);
      }
    },    
  },
  umnounted() { },
  computed: {
    tableTitle: function () {
      return "<div class='title'><h4 class='text-primary'>" + this.chart.title + "</h4></div>";
    },
    showSummarySmartCenterLink: function () {
      return true;
    }
  },
  props: {},
  mounted() {},
  created() {
    this.chart = ChartService.getChartAttributes("Trends");
  },
  watch: {}
};
</script>
